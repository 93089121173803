import React from 'react';
import PropTypes from 'prop-types';
import logoM from './logo.jpeg';
import css from './Logo.module.css';

const IconLogo = props => {
  const { className, ...rest } = props;

  return <img src={logoM} className={css.mobileLogo} />;
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
