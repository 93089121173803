import React, { useState, useEffect } from 'react';
import { Button } from '../../components';
import css from './ProfileSettingsForm.module.css';

function CheckId() {
  const [images, setImages] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    if (images.length < 1) return;

    const newImageURLs = [];
    images.forEach(image => newImageURLs.push(URL.createObjectURL(image)));
    setImageURLs(newImageURLs);
  }, [images]);

  function onImageChange(e) {
    setImages([...e.target.files]);
  }

  const handleVerify = () => {
    setVerified(true);
  };

  return (
    <>
      <h3 className={css.sectionTitle}>ID verification</h3>

      {verified ? (
        <p className={css.greenText}>Your ID was verified</p>
      ) : (
        <>
          <input type="file" multiple accept="image/*" onChange={onImageChange} />
          <div className={css.imagesWrapper}>
            {imageURLs.map(imageSrc => (
              <img className={css.imagePreview} src={imageSrc} />
            ))}
          </div>

          {images.length > 1 ? (
            <Button className={css.checkButton} onClick={handleVerify}>
              Check
            </Button>
          ) : null}
        </>
      )}
    </>
  );
}

export default CheckId;
