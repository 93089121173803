import React, { useEffect, useState } from 'react';
import { Button } from '../../components';
import css from './ContactDetailsForm.module.css';
import { post } from '../../util/api';
const sharetribeSdk = require('sharetribe-flex-sdk');
// import { indexOf } from 'core-js/core/array';

const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const getCode = str => {
  const lastSix = str.substr(str.length - 6);
  const reversedCharacters = lastSix.split('').join('');
  const finalCode = reversedCharacters.toUpperCase();
  return finalCode;
};

function VerifyPhone(props) {
  const { currentUser } = props;

  const [codeSend, setCodeSend] = useState(false);
  const [error, setError] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  const phoneNumber = currentUser.attributes.profile.protectedData.phoneNumber;
  const userId = currentUser.id?.uuid;
  const verifyPhoneNumber = () => {
    const lastSix = userId.slice(-6);
    const convertedCode = verificationCode.toLowerCase();

    if (lastSix === convertedCode) {
      return sdk.currentUser
        .updateProfile({
          protectedData: {
            phoneVerified: true,
          },
        })
        .then(res => {
          if (typeof window !== 'undefined') {
            return window.location.reload();
          } else {
            return true;
          }
        })
        .catch(e => console.log(e));
    } else {
      setError(true);
    }
  };

  const sendVerificationCode = () => {
    const body = {
      phoneNumber,
      messageText: `Your verification code for Ekoluv is ${getCode(userId)}`,
    };

    return post('/api/send-sms', body)
      .then(resp => {
        setCodeSend(true);
      })
      .catch(e => {
        setError(true);
      });
  };

  const handleVerificationCode = e => {
    setVerificationCode(e.target.value);
  };

  return (
    <div>
      <br />
      <p className={css.label}>Verify phone number</p>
      {codeSend ? (
        <div className={css.phoneVerificationWrapper}>
          <input
            value={verificationCode}
            onChange={handleVerificationCode}
            placeholder="Type code here"
          />
          <Button
            type="button"
            className={css.verifyButton}
            disabled={verificationCode.length < 5}
            onClick={verifyPhoneNumber}
          >
            Verify
          </Button>
        </div>
      ) : (
        <>
          <Button type="button" onClick={sendVerificationCode}>
            Send verification code
          </Button>
        </>
      )}

      {error ? <p className={css.errorText}>Woops! Something went wrong, try again.</p> : null}
    </div>
  );
}

export default VerifyPhone;
