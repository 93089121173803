import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import ShoppingCart from './ShoppingCart';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import NotificationsMenu from './NotificationsMenu';
import css from './TopbarDesktop.module.css';

import Grid from '@mui/material/Grid';
import HoverDropdown from './HoverDropdown';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    history,
    landingPage,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink className={css.profileSettingsLink} name="ProfileSettingsPage">
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="ManageListingsPage1">
          <NamedLink className={css.yourListingsLink} name="ManageListingsPage">
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>

        {/* fav listings */}
        <MenuItem key="FavListingsPage">
          <NamedLink className={css.yourListingsLink} name="FavListingsPage">
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.FavListingsPage" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="InboxPage1">
          <NamedLink className={css.yourListingsLink} name="InboxPage" params={{ tab: 'sales' }}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inbox" />
          </NamedLink>
        </MenuItem>

        {/* <MenuItem key="SeparatingLine1">
          <span className={css.line}></span>
        </MenuItem>

        <MenuItem key="InboxPage2">
          <NamedLink className={css.yourListingsLink} name="InboxPage" params={{ tab: 'orders' }}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inbox2" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="InboxPage3">
          <NamedLink className={css.yourListingsLink} name="InboxPage" params={{ tab: 'orders' }}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inbox3" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="ManageListingsPage2">
          <NamedLink className={css.yourListingsLink} name="ManageListingsPage">
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink2" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="SeparatingLine2">
          <span className={css.line}></span>
        </MenuItem>

        <MenuItem key="InboxPage4">
          <NamedLink className={css.yourListingsLink} name="InboxPage" params={{ tab: 'sales' }}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inbox4" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="InboxPage5">
          <NamedLink className={css.yourListingsLink} name="InboxPage" params={{ tab: 'sales' }}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inbox5" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="InboxPage6">
          <NamedLink className={css.yourListingsLink} name="InboxPage" params={{ tab: 'sales' }}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inbox6" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="SeparatingLine3">
          <span className={css.line}></span>
        </MenuItem>

        <MenuItem key="FollowersPage">
          <NamedLink className={css.yourListingsLink} name="FollowersPage">
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.followersPageLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="ReferralPage">
          <NamedLink className={css.yourListingsLink} name="ReferralPage">
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.referralPageLink" />
          </NamedLink>
        </MenuItem> */}

        <MenuItem key="AccountSettingsPage">
          <NamedLink className={css.yourListingsLink} name="AccountSettingsPage">
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        {/* 
        <MenuItem key="MasterCalendarPage">
          <NamedLink className={css.yourListingsLink} name="MasterCalendarPage">
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.userDashboard" />
          </NamedLink>
        </MenuItem> */}

        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const dropdownDummy = [
    {
      header: intl.formatMessage({ id: 'DropdownColumns.woman' }),
      items: [
        {
          label: 'Lorem ipsum',
          path: '/s',
        },
        {
          label: 'Lorem ipsum',
          path: '/s',
        },
        {
          label: 'Lorem ipsum',
          path: '/s',
        },
        {
          label: 'Lorem ipsum',
          path: '/s',
        },
        {
          label: 'Lorem ipsum',
          path: '/s',
        },
        {
          label: 'Lorem ipsum',
          path: '/s',
        },
        {
          label: 'Lorem ipsum',
          path: '/s',
        },
      ],
    },
    {
      header: intl.formatMessage({ id: 'DropdownColumns.men' }),
      items: [
        {
          label: 'Lorem ipsum',
          path: '/s',
        },
        {
          label: 'Lorem ipsum',
          path: '/s',
        },
        {
          label: 'Lorem ipsum',
          path: '/s',
        },
        {
          label: 'Lorem ipsum',
          path: '/s',
        },
        {
          label: 'Lorem ipsum',
          path: '/s',
        },
      ],
    },
  ];

  if (landingPage) {
    return (
      <nav className={css.wrapperForHoverMenu}>
        <div className={classes}>
          <div className={css.sectionLeft}>{search}</div>
          <div className={css.sectionCenter}>
            <NamedLink className={css.logoLink} name="LandingPage">
              <Logo
                format="desktop"
                className={css.logo}
                alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
              />
            </NamedLink>
          </div>
          <div className={css.sectionRight}>
            <NamedLink className={css.createListingLink} name="RotateItemPage">
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.createListing" />
              </span>
            </NamedLink>
            {inboxLink}

            {isAuthenticatedOrJustHydrated ? (
              <ShoppingCart intl={intl} history={history} currentUser={currentUser} />
            ) : null}

            {/* <NotificationsMenu currentUser={currentUser} /> */}

            {profileMenu}

            {signupLink}
            {loginLink}
          </div>
        </div>
        <div className={css.secondRow}>
          <Grid container item xs={12} justifyContent="center">
            <NamedLink className={css.navigationLink} name="SearchPage">
              New In
            </NamedLink>

            <NamedLink className={css.navigationLink} name="SearchPage">
              Shop by designer
            </NamedLink>

            <NamedLink className={css.navigationLink} name="SearchPage">
              Buy preloved
            </NamedLink>

            <NamedLink className={css.navigationLink} name="SearchPage">
              Rent
            </NamedLink>

            <NamedLink className={css.navigationLink} name="SearchPage">
              Membership
            </NamedLink>

            <NamedLink className={css.navigationLink} name="SearchPage">
              Consign
            </NamedLink>

            {/* <HoverDropdown label={'Activewear'} columns={dropdownDummy} />

            <HoverDropdown label={'Bags'} columns={dropdownDummy} />

            <HoverDropdown label={'Bottoms'} columns={dropdownDummy} />

            <HoverDropdown label={'Dresses'} columns={dropdownDummy} />

            <HoverDropdown label={'Eyewear'} columns={dropdownDummy} />

            <HoverDropdown label={'Footwear'} columns={dropdownDummy} />

            <HoverDropdown label={'Headwear'} columns={dropdownDummy} />

            <HoverDropdown label={'Jewellery'} columns={dropdownDummy} />

            <HoverDropdown label={'Lingerie'} columns={dropdownDummy} />

            <HoverDropdown label={'Tops'} columns={dropdownDummy} /> */}
          </Grid>
        </div>
      </nav>
    );
  }

  return (
    <nav className={classes}>
      <div className={css.sectionLeft}>{search}</div>
      <div className={css.sectionCenter}>
        <NamedLink className={css.logoLink} name="LandingPage">
          <Logo
            format="desktop"
            className={css.logo}
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
          />
        </NamedLink>
      </div>
      <div className={css.sectionRight}>
        <NamedLink className={css.createListingLink} name="RotateItemPage">
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.createListing" />
          </span>
        </NamedLink>
        {inboxLink}

        {isAuthenticatedOrJustHydrated ? (
          <ShoppingCart intl={intl} history={history} currentUser={currentUser} />
        ) : null}

        {/* <NotificationsMenu currentUser={currentUser} /> */}

        {profileMenu}

        {signupLink}
        {loginLink}
      </div>
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
