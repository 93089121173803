import React, { useEffect } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import { TopbarSearchForm } from '../../forms';
import config from '../../config';
import { parse, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import AwesomeSlider from 'react-awesome-slider';
import './carousel.css';
import css from './SectionHero.module.css';

const SectionHero = props => {
  const { rootClassName, className } = props;

  const classes1 = classNames(rootClassName || css.root1, className);
  const classes2 = classNames(rootClassName || css.root2, className);
  const classes3 = classNames(rootClassName || css.root3, className);

  useEffect(() => {
    if (typeof window === 'undefined') {
      console.log('ceva');
    } else {
      require('react-awesome-slider/dist/styles.css');
    }
  }, []);

  return (
    <AwesomeSlider animation="cubeAnimation" bullets={false}>
      <div className={classes1}>
        <div className={css.heroContent}>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionHero.title" />
          </h1>
          <h2 className={css.heroSubTitle}>
            <FormattedMessage id="SectionHero.subTitle" />
          </h2>

          <NamedLink
            name="SearchPage"
            to={{
              search: ' ',
            }}
            className={css.heroButton}
          >
            <FormattedMessage id="SectionHero.browseButton" />
          </NamedLink>
        </div>
      </div>
      <div className={classes2}>
        <div className={css.heroContent}>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionHero.title" />
          </h1>
          <h2 className={css.heroSubTitle}>
            <FormattedMessage id="SectionHero.subTitle" />
          </h2>
          <NamedLink
            name="SearchPage"
            to={{
              search: ' ',
            }}
            className={css.heroButton}
          >
            <FormattedMessage id="SectionHero.browseButton" />
          </NamedLink>
        </div>
      </div>
    </AwesomeSlider>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
