import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldSelect, FieldCheckboxGroup } from '../../components';
import FieldMultipleSelect from '../EditListingFeaturesForm/FieldMultipleSelect';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import arrayMutators from 'final-form-arrays';
import FieldToogle from '../../components/FieldToogle/FieldToogle';
import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        categories,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        panelTitle,
      } = formRenderProps;

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const sizingOptions = findOptionsForSelectFilter('sizing', config.custom.filters);
      const coloursOptions = findOptionsForSelectFilter('colours', config.custom.filters);
      const conditionOptions = findOptionsForSelectFilter('condition', config.custom.filters);
      const occasionOptions = findOptionsForSelectFilter('occasion', config.custom.filters);
      const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
      const subcategoryOptions = findOptionsForSelectFilter('subcategory', config.custom.filters);
      const brandOptions = findOptionsForSelectFilter('designer', config.custom.filters);

      const toogleLabel =
        values.isProductForSale && values.isProductForSale[0] === 'true' ? 'For sale' : 'For rent';
      const isForSale = toogleLabel === 'For sale';

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.headingWrapper}>
            {panelTitle}
            <FieldToogle id="isProductForSale" name={'isProductForSale'} label={toogleLabel} />
          </div>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />

          <FieldSelect
            id="brand"
            name="brand"
            label="Brand"
            validate={required('You need to select a brand')}
            className={css.selectField}
          >
            <option value="" disabled hidden>
              Pick a brand...
            </option>
            {brandOptions.map(x => {
              return <option value={x.key}>{x.label}</option>;
            })}
          </FieldSelect>

          <FieldSelect
            id="category"
            name="category"
            label="Category"
            className={css.selectField}
            validate={required('You need to select a category')}
          >
            <option value="" disabled hidden>
              Pick a category...
            </option>
            {categoryOptions.map(x => {
              return <option value={x.key}>{x.label}</option>;
            })}
          </FieldSelect>

          <FieldTextInput
            id="style"
            name="style"
            className={css.title}
            type="text"
            label={'Style'}
            placeholder={'Type here...'}
            validate={composeValidators(required('You need to complete this field'))}
            autoFocus
          />

          <FieldTextInput
            id="material"
            name="material"
            className={css.title}
            type="text"
            label={'Material'}
            placeholder={'Cotton'}
            validate={composeValidators(required('You need to complete this field'))}
            autoFocus
          />

          <FieldTextInput
            id="madeIn"
            name="madeIn"
            className={css.title}
            type="text"
            label={'Made in'}
            placeholder={'Australia'}
            validate={composeValidators(required('You need to complete this field'))}
            autoFocus
          />

          {isForSale && (
            <FieldTextInput
              id="mesurements"
              name="mesurements"
              className={css.title}
              type="text"
              label={'Measurements'}
              placeholder={'Type here...'}
              validate={composeValidators(required('You need to complete this field'))}
              autoFocus
            />
          )}

          {/* {values.category && (
            <FieldSelect
              id="subcategory"
              name="subcategory"
              label="Subcategory"
              className={css.selectField}
              validate={required('You need to select a subcategory')}
            >
              <option value="" disabled hidden>
                Pick a subcategory...
              </option>
              {subcategoryOptions
                .filter(s => s.category === values.category)
                .map(x => {
                  return <option value={x.key}>{x.label}</option>;
                })}
            </FieldSelect>
          )} */}

          <p className={css.checkboxGroupLabel}>Size</p>
          <FieldCheckboxGroup
            className={css.features}
            id={'sizing'}
            name={'sizing'}
            options={sizingOptions}
            twoColumns={true}
          />

          {/* <FieldSelect
            id="sizing"
            name="sizing"
            label="Size"
            className={css.selectField}
            validate={required('You need to select a size')}
          >
            <option value="">Pick a size...</option>
            {sizingOptions.map(x => {
              return <option value={x.key}>{x.label}</option>;
            })}
          </FieldSelect> */}

          {/* <FieldSelect
            id="colours"
            name="colours"
            label="Colour"
            validate={required('You need to select a colour')}
          >
            <option value="">Pick a colour...</option>
            {coloursOptions.map(x => {
              return <option value={x.key}>{x.label}</option>;
            })}
          </FieldSelect> */}

          {/* <FieldMultipleSelect
            id="colours"
            name="colours"
            className={css.selectField}
            label={'Colour'}
            options={coloursOptions}
            validate={required('You need to select a colour')}
            placeholder={'Pick a colour...'}
            // intl={intl}
          /> */}

          <p className={css.checkboxGroupLabel}>Colours</p>
          <FieldCheckboxGroup
            className={css.features}
            id={'colours'}
            name={'colours'}
            options={coloursOptions}
            twoColumns={true}
          />

          {isForSale && (
            <FieldSelect
              id="condition"
              name="condition"
              label="Condition"
              className={css.selectField}
              validate={required('You need to select an option')}
            >
              <option value="" disabled hidden>
                Pick an option...
              </option>
              {conditionOptions.map(x => {
                return <option value={x.key}>{x.label}</option>;
              })}
            </FieldSelect>
          )}

          {/* <FieldSelect 
          id="occasion" 
          name="occasion" 
          label="Occasion" 
          validate={required('You need to select an option')}
          >
            <option value="">Pick an option...</option>
            {occasionOptions.map(x => {
              return(
                <option value={x.key}>{x.label}</option>
              )
            })}            
          </FieldSelect> */}

          {/* {!isForSale && (
            <>
              <p className={css.checkboxGroupLabel}>Occasion</p>
              <FieldCheckboxGroup
                className={css.features}
                id={'occasion'}
                name={'occasion'}
                options={occasionOptions}
                twoColumns={true}
              />
            </>
          )} */}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
