import React, { Component, useState, useEffect } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import VerifiedIcon from '@mui/icons-material/Verified';

import css from './ListingCard.module.css';
const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});
const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    filtersConfig,
    setActiveListing,
    favListings,
    listingExpanded,
    showAuthorInfo,
    followersPage,
    isAll20,
  } = props;

  const listingId = listing && listing.id.uuid;
  const [isFavourite, setIsFavourite] = useState(false);
  const [favListingsArray, setFavListingsArray] = useState([]);

  useEffect(() => {
    sdk.currentUser
      .show()
      .then(res => {
        if (
          res.data.data.attributes.profile.privateData &&
          res.data.data.attributes.profile.privateData.favListingsArray
        ) {
          setFavListingsArray(res.data.data.attributes.profile.privateData.favListingsArray);
          const listingsIdsArray = res.data.data.attributes.profile.privateData.favListingsArray;
          const isFavourite = listingsIdsArray.find(id => {
            return id === listingId;
          });
          if (isFavourite) {
            setIsFavourite(true);
          }
        }
      })
      .catch(e => console.log(e));
  }, []);

  const addToFavourites = action => {
    if (action === 'add') {
      console.log([...favListingsArray, listingId]);
      return sdk.currentUser
        .updateProfile({
          privateData: {
            favListingsArray: [...favListingsArray, listingId],
          },
        })
        .then(resp => {
          return setIsFavourite(true);
        })
        .catch(e => console.log(e));
    } else {
      const favListingsArrayCopy = [...favListingsArray];

      for (var i = 0; i < favListingsArrayCopy.length; i++) {
        if (favListingsArrayCopy[i] === listingId) {
          favListingsArrayCopy.splice(i, 1);
        }
      }

      return sdk.currentUser
        .updateProfile({
          privateData: {
            favListingsArray: favListingsArrayCopy,
          },
        })
        .then(resp => {
          return setIsFavourite(false);
        })
        .catch(e => console.log(e));
    }
  };

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const publicData = currentListing?.attributes?.publicData || {};
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  // const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  // const variants = firstImage
  //   ? Object.keys(firstImage?.attributes?.variants)
  //   : [];

  const { formattedPrice, priceTitle } = priceData(price, intl);
  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const isProduct =
    Array.isArray(publicData.isProductForSale) && publicData.isProductForSale[0] === 'true';
  const unitTranslationKey = isProduct
    ? 'ListingCard.perUnit'
    : isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const isVerifiedByEkoluv = publicData.verified && publicData.verified === 'ekoluv';
  const isVerifiedByEntropy = publicData.verified && publicData.verified === 'entropy';

  const isDonationListing =
    publicData.listingType === 'donation' || publicData.listingType === 'carbonFootprint';

  return (
    <NamedLink
      className={classes}
      name="ListingPage"
      params={{ id, slug }}
      state={{ isAll20: !!isAll20 }}
    >
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            favListings={favListings}
            variants={['portrait-crop', 'portrait-crop2x']}
            sizes={renderSizes}
          />
          {(isVerifiedByEkoluv || isVerifiedByEntropy) && (
            <div className={css.verifiedIconWrapper}>
              <VerifiedIcon fontSize="medium" className={css.verifiedIcon} />
              <p className={css.verifiedText}>
                Verified by {isVerifiedByEkoluv ? 'Ekoluv' : 'Entropy'}
              </p>
            </div>
          )}
          <div
            className={css.favButtonWrapper}
            onClick={e => {
              e.preventDefault();
            }}
          >
            {!isFavourite ? (
              <FavoriteBorderIcon
                onClick={e => {
                  e.preventDefault();
                  addToFavourites('add');
                }}
                className={css.favButtonNotSelected}
              />
            ) : (
              <FavoriteIcon
                onClick={e => {
                  e.preventDefault();
                  addToFavourites('remove');
                }}
                className={css.favButtonSelected}
              />
            )}
          </div>
        </div>
      </div>
      <div className={css.info}>
        {!isDonationListing && (
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
            <div className={css.perUnit}>
              <FormattedMessage id={unitTranslationKey} />
            </div>
          </div>
        )}

        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {!isDonationListing && (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.hostedBy" values={{ authorName }} />
            </div>
          )}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
