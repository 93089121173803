import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import './FieldMultipleSelect.css';
import { isArray } from 'lodash';

function FieldMultipleSelect(props) {
  const { id, name, className, label, validate, options, placeholder, intl } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    variant: 'menu',
  };

  const multiSelectField = ({ input: { onChange, value }, label, ...rest }) => {
    const initialValues = value && isArray(value) ? value : [];
    const [selected, setSelected] = useState(initialValues);

    const handleChange = event => {
      event.preventDefault();
      const value = event.target.value;
      onChange(value);
      setSelected(value);
    };

    return (
      <div className="selectFieldWrapper">
        <InputLabel id={id}>{label}</InputLabel>
        <Select
          labelId={id}
          multiple
          value={selected}
          displayEmpty
          onChange={handleChange}
          renderValue={selected => {
            if (selected.length > 0) {
              return (
                <span className="selectedValue">
                  {selected
                    .map(s => {
                      const foundOption = options.find(o => o.key === s);

                      //uncomment this if you use translations for labels
                      // if (foundOption?.label?.props?.id) {
                      //   return intl.formatMessage({ id: foundOption.label.props.id });
                      // } else {
                      return foundOption.label;
                      // }
                    })
                    .join(', ')}
                </span>
              );
            } else {
              return <span className="placeholderText">{placeholder}</span>;
            }
          }}
          MenuProps={MenuProps}
          className="selectField"
        >
          {options.map(option => (
            <MenuItem key={option.key} value={option.key}>
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(option.key) > -1} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <div className="optionLabelWrapper">
                    {option.hex && (
                      <div
                        style={{ backgroundColor: `${option.hex}` }}
                        className="colorBullet"
                      ></div>
                    )}
                    {option.label}
                  </div>
                }
              />
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  };

  return <Field name={name} label={label} component={multiSelectField} validate={validate} />;
}

export default FieldMultipleSelect;
