import React, {useState, useEffect} from 'react';
import css from './ContactDetailsForm.module.css';
import axios from 'axios';
import { post } from '../../util/api';
const sharetribeSdk = require('sharetribe-flex-sdk');
// import { indexOf } from 'core-js/core/array';

const sdk = sharetribeSdk.createInstance({
    clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID
  });

function VerifyId(props) {

    const { currentUser } = props;
    const inputFileRef = React.createRef();
    const idCard = currentUser?.attributes.profile.publicData.idCard?.name;
    const isPendingVerification = currentUser?.attributes.profile.publicData.idCard?.verified === 'pending';
    const alreadySavedPdfLabel = idCard ? idCard.split('-')[2] : "";

    const [file, setFile] = useState(false);
    const [fileSend, setFileSend] = useState(false);
    const handleFileUpload = () => {

        const origin = typeof window === 'undefined' ? '' : (window.location.origin === 'http://localhost:3000' ? 'http://localhost:3500' : window.location.origin);

        //Create an object of formData 
      const formData = new FormData(); 
    
      // Update the formData object 
      formData.append( 
        "image", 
        file, 
        file.name 
      ); 
    
      return axios.post(`${origin}/api/azure-upload`, formData).then(resp => {
        const fileName = resp.data.fileName;
        
            return sdk.currentUser.updateProfile({
                publicData: {
                    idCard: {
                        name: fileName,
                        verified: 'pending'
                    }
                },
              }).then(response => {
                          setFileSend(true)
                          return true
                        }).catch(error => console.log(error))
      }).catch(error => console.log(error))
    
    }

    
    const handleBtnClick = () => {
        /*Collecting node-element and performing click*/
        if(isPendingVerification){
            //ignore
        }else{
            inputFileRef.current.click();
        }
      }

     const onFileChange = event => { 
        // Update the state 
        
      const infoFile = event.target.files[0];
      setFile(infoFile)
      }; 

  return (
      <div>
    <div className={css.label}>Id card</div>

  <div className={css.pdfUpload}>
                <input
                type="file"
                id="image"
                onChange={onFileChange} 
                 ref={inputFileRef} 
                className={css.hiddenUploadInput}
                />
                <div onClick={handleBtnClick} className={ idCard ? css.dropZoneFilled : (file ? css.dropZoneFilled :  css.dropZone)}>
                  <span className={css.dropZoneLabel}>{isPendingVerification ? "Pending verification" : (idCard && !file  ? alreadySavedPdfLabel : (file ? file.name : 'Click here to upload file'))}</span>
                  </div>
                  {isPendingVerification ? 
                    null :
                    <button type="button" onClick={handleFileUpload} className={ file ? css.uploadButton : css.uploadButtonDisabled}>{fileSend ? "✓" : 'Upload'}</button>

                    }
            </div>
     </div>
  )
}

export default VerifyId