import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';

import { propTypes } from '../../util/types';
import config from '../../config';
import { required, bookingDatesRequired, composeValidators } from '../../util/validators';
import { Button, FieldCheckboxGroup, Form, FieldTextInput, FieldSelect } from '../../components';
import FieldMultipleSelect from './FieldMultipleSelect';
import css from './EditListingFeaturesForm.module.css';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const sizingOptions = findOptionsForSelectFilter('sizing', config.custom.filters);
      const coloursOptions = findOptionsForSelectFilter('colours', config.custom.filters);
      const conditionOptions = findOptionsForSelectFilter('condition', config.custom.filters);
      const occasionOptions = findOptionsForSelectFilter('occasion', config.custom.filters);
      const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
      const brandOptions = findOptionsForSelectFilter('designer', config.custom.filters);
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          {/* <FieldTextInput
            id="brand"
            name="brand"
            className={css.description}
            type="text"
            label={"Brand"}
            placeholder={'What is the brand of your dress ?'}
            validate={composeValidators(required('You need to complete this field'))}
          /> */}

          <FieldSelect
            id="brand"
            name="brand"
            label="Brand"
            validate={required('You need to select a brand')}
          >
            <option value="">Pick a brand...</option>
            {brandOptions.map(x => {
              return <option value={x.key}>{x.label}</option>;
            })}
          </FieldSelect>

          <FieldSelect
            id="category"
            name="category"
            label="Category"
            validate={required('You need to select a category')}
          >
            <option value="">Pick a category...</option>
            {categoryOptions.map(x => {
              return <option value={x.key}>{x.label}</option>;
            })}
          </FieldSelect>

          <FieldSelect
            id="sizing"
            name="sizing"
            label="Size"
            validate={required('You need to select a size')}
          >
            <option value="">Pick a size...</option>
            {sizingOptions.map(x => {
              return <option value={x.key}>{x.label}</option>;
            })}
          </FieldSelect>

          {/* <FieldSelect
            id="colours"
            name="colours"
            label="Colour"
            validate={required('You need to select a colour')}
          >
            <option value="">Pick a colour...</option>
            {coloursOptions.map(x => {
              return <option value={x.key}>{x.label}</option>;
            })}
          </FieldSelect> */}

          <FieldMultipleSelect
            id="colours"
            name="colours"
            className={css.selectField}
            label={'Colour'}
            options={coloursOptions}
            validate={required('You need to select a colour')}
            placeholder={'Pick a colour...'}
            // intl={intl}
          />

          <FieldSelect
            id="condition"
            name="condition"
            label="Condition"
            validate={required('You need to select an option')}
          >
            <option value="" disabled hidden>
              Pick an option...
            </option>
            {conditionOptions.map(x => {
              return <option value={x.key}>{x.label}</option>;
            })}
          </FieldSelect>

          {/* <FieldSelect 
          id="occasion" 
          name="occasion" 
          label="Occasion" 
          validate={required('You need to select an option')}
          >
            <option value="">Pick an option...</option>
            {occasionOptions.map(x => {
              return(
                <option value={x.key}>{x.label}</option>
              )
            })}            
          </FieldSelect> */}

          <p className={css.checkboxGroupLabel}>Occasion</p>
          <FieldCheckboxGroup
            className={css.features}
            id={'occasion'}
            name={'occasion'}
            options={occasionOptions}
            twoColumns={true}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
