/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'isProductForSale',
    label: 'Item type',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_isProductForSale'],
    config: {
      schemaType: 'multi-enum',
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'true', label: 'For Sale' },
        { key: 'false', label: 'For Rent' },
        { key: 'swap', label: 'For Swap' },
        { key: 'free', label: 'For Free' },
      ],
    },
  },

  //hidden filters below
  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'vendorBuy',
    label: 'Vendor',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_vendorBuy'],
    config: {
      // schemaType: 'multi-enum',
      // searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'rotationRecycles', label: 'Rotation Recycles' },
        { key: 'all', label: 'All' },
      ],
    },
  },
  {
    id: 'vendorRent',
    label: 'Vendor',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_vendorRent'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'rotationRents', label: 'Rotation Rents' },
        { key: 'friendsOfRotation', label: 'Friends of Rotation' },
        { key: 'all', label: 'All' },
      ],
    },
  },
  {
    id: 'category',
    label: 'Category',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      schemaType: 'multi-enum',
      searchMode: 'has_any',

      options: [
        { key: 'activewear', label: 'Activewear' },
        { key: 'bags', label: 'Bags' },
        { key: 'bottoms', label: 'Bottoms' },
        { key: 'dresses', label: 'Dresses' },
        { key: 'eyewear', label: 'Eyewear' },
        { key: 'footwear', label: 'Footwear' },
        { key: 'headwear', label: 'Headwear' },
        { key: 'jewellery', label: 'Jewellery' },
        { key: 'lingerie', label: 'Lingerie' },
        { key: 'otherAccessories', label: 'Other Accessories' },
        { key: 'playsuits', label: 'Playsuits' },
        { key: 'swimwear', label: 'Swimwear' },
        { key: 'tops', label: 'Tops' },
      ],
    },
  },
  {
    id: 'subcategory',
    label: 'Subcategory',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_subcategory'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      schemaType: 'multi-enum',
      searchMode: 'has_any',

      options: [
        //subcategories for bag category
        { key: 'baguetteBags', label: 'Baguette Bags', category: 'bags' },
        { key: 'bostonBags', label: 'Boston Bags', category: 'bags' },
        { key: 'handbags', label: 'Handbags', category: 'bags' },
        { key: 'clutches', label: 'Clutches', category: 'bags' },
        { key: 'travelBags', label: 'Travel Bags', category: 'bags' },
        { key: 'shoulderBags', label: 'Shoulder Bags', category: 'bags' },
        { key: 'backpacks', label: 'Backpacks', category: 'bags' },
        { key: 'bucketBags', label: 'Bucket Bags', category: 'bags' },
        { key: 'beltBagss', label: 'Belt Bagss', category: 'bags' },
        { key: 'toteBags', label: 'Tote Bags', category: 'bags' },
        { key: 'satchels', label: 'Satchels', category: 'bags' },
        { key: 'crossbodyBags', label: 'Crossbody Bags', category: 'bags' },
        { key: 'others', label: 'Others', category: 'bags' },
        //subcategories for bottoms category
        { key: 'pants', label: 'Pants', category: 'bottoms' },
        { key: 'jeans', label: 'Jeans', category: 'bottoms' },
        { key: 'shorts', label: 'Shorts', category: 'bottoms' },
        { key: 'skirts', label: 'Skirts', category: 'bottoms' },
        //subcategories for dresses category
        { key: 'mini', label: 'Mini', category: 'dresses' },
        { key: 'midi', label: 'Midi', category: 'dresses' },
        { key: 'maxi', label: 'Maxi', category: 'dresses' },
        //subcategories for footwear category
        { key: 'sportsShoes', label: 'Sports Shoes', category: 'footwear' },
        { key: 'boots', label: 'Boots', category: 'footwear' },
        { key: 'heels', label: 'Heels', category: 'footwear' },
        { key: 'flats', label: 'Flats', category: 'footwear' },
        //subcategories for jewellery category
        { key: 'rings', label: 'Rings', category: 'jewellery' },
        { key: 'bracelets', label: 'Bracelets', category: 'jewellery' },
        { key: 'necklaces', label: 'Necklaces', category: 'jewellery' },
        { key: 'charms', label: 'Charms', category: 'jewellery' },
        { key: 'earrings', label: 'Earrings', category: 'jewellery' },
        { key: 'other', label: 'Other', category: 'jewellery' },
        //subcategories for otherAccessories category
        { key: 'watches', label: 'Watches', category: 'otherAccessories' },
        { key: 'gloves', label: 'Gloves', category: 'otherAccessories' },
        { key: 'scarves', label: 'Scarves', category: 'otherAccessories' },
        { key: 'others', label: 'Others', category: 'otherAccessories' },
        //subcategories for swimwear category
        { key: 'separates', label: 'Separates', category: 'swimwear' },
        { key: 'onePiece', label: 'One Piece', category: 'swimwear' },
        //subcategories for tops category
        { key: 'tops', label: 'Tops', category: 'tops' },
        { key: 'shirts', label: 'Shirts', category: 'tops' },
        { key: 'jumpers', label: 'Jumpers', category: 'tops' },
        { key: 'jacketsCoats', label: 'Jackets & Coats', category: 'tops' },
      ],
    },
  },
  {
    id: 'designer',
    label: 'Designer',
    type: 'SelectSearchableFilter',
    group: 'primary',
    queryParamNames: ['pub_designer'],
    config: {
      schemaType: 'multi-enum',
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'Abrand',
          label: 'Abrand',
        },
        {
          key: 'Abyss by Abby',
          label: 'Abyss by Abby',
        },
        {
          key: 'Acler',
          label: 'Acler',
        },
        {
          key: 'Adidas',
          label: 'Adidas',
        },
        {
          key: 'AJE',
          label: 'AJE',
        },
        {
          key: 'Alamour the Label',
          label: 'Alamour the Label',
        },
        {
          key: 'Alexander McQueen',
          label: 'Alexander McQueen',
        },
        {
          key: 'Alexander Wang',
          label: 'Alexander Wang',
        },
        {
          key: 'Alexis',
          label: 'Alexis',
        },
        {
          key: 'Alias Mae',
          label: 'Alias Mae',
        },
        {
          key: 'Alice McCall',
          label: 'Alice McCall',
        },
        {
          key: 'Alimaes',
          label: 'Alimaes',
        },
        {
          key: 'AllSaints',
          label: 'AllSaints',
        },
        {
          key: 'Amber Sceats',
          label: 'Amber Sceats',
        },
        {
          key: 'Ambra Maddalena',
          label: 'Ambra Maddalena',
        },
        {
          key: 'Andrej Urem',
          label: 'Andrej Urem',
        },
        {
          key: 'Anine Bing',
          label: 'Anine Bing',
        },
        {
          key: 'Anna Quan',
          label: 'Anna Quan',
        },
        {
          key: 'Arcina Ori',
          label: 'Arcina Ori',
        },
        {
          key: 'Assembly Label',
          label: 'Assembly Label',
        },
        {
          key: 'Atomos & Here',
          label: 'Atomos & Here',
        },
        {
          key: 'Auguste the Label',
          label: 'Auguste the Label',
        },
        {
          key: 'Augustine',
          label: 'Augustine',
        },
        {
          key: 'Balenciaga',
          label: 'Balenciaga',
        },
        {
          key: 'Bamba Swim',
          label: 'Bamba Swim',
        },
        {
          key: 'Bassike',
          label: 'Bassike',
        },
        {
          key: 'Beau Coops',
          label: 'Beau Coops',
        },
        {
          key: 'Bec and Bridge',
          label: 'Bec and Bridge',
        },
        {
          key: 'Bella Raine',
          label: 'Bella Raine',
        },
        {
          key: 'Benjamin Alexander',
          label: 'Benjamin Alexander',
        },
        {
          key: 'Blak',
          label: 'Blak',
        },
        {
          key: 'Blanca',
          label: 'Blanca',
        },
        {
          key: 'Bottega Veneta',
          label: 'Bottega Veneta',
        },
        {
          key: 'Brie Leon',
          label: 'Brie Leon',
        },
        {
          key: 'Brie Leon',
          label: 'Brie Leon',
        },
        {
          key: 'By Charlotte',
          label: 'By Charlotte',
        },
        {
          key: 'By Johnny',
          label: 'By Johnny',
        },
        {
          key: 'By Nicola',
          label: 'By Nicola',
        },
        {
          key: 'C/MEO Collective',
          label: 'C/MEO Collective',
        },
        {
          key: 'Caitlin Crisp',
          label: 'Caitlin Crisp',
        },
        {
          key: 'Calvin Klein',
          label: 'Calvin Klein',
        },
        {
          key: 'Camilla',
          label: 'Camilla',
        },
        {
          key: 'Camilla & Marc',
          label: 'Camilla & Marc',
        },
        {
          key: 'Carla Zampatti',
          label: 'Carla Zampatti',
        },
        {
          key: 'Cartier',
          label: 'Cartier',
        },
        {
          key: 'Cecile Copenhagen',
          label: 'Cecile Copenhagen',
        },
        {
          key: 'Celine',
          label: 'Celine',
        },
        {
          key: 'Chanel',
          label: 'Chanel',
        },
        {
          key: 'Chaos And Harmony',
          label: 'Chaos And Harmony',
        },
        {
          key: 'Chloe',
          label: 'Chloe',
        },
        {
          key: 'Christopher Esber',
          label: 'Christopher Esber',
        },
        {
          key: 'Comme Des Garcon',
          label: 'Comme Des Garcon',
        },
        {
          key: 'COOP by Trelise Cooper',
          label: 'COOP by Trelise Cooper',
        },
        {
          key: 'Country Road',
          label: 'Country Road',
        },
        {
          key: 'Cult Gaia',
          label: 'Cult Gaia',
        },
        {
          key: 'Deadly Ponies',
          label: 'Deadly Ponies',
        },
        {
          key: 'Decjuba',
          label: 'Decjuba',
        },
        {
          key: 'Dion Lee',
          label: 'Dion Lee',
        },
        {
          key: 'Djerf Avenue',
          label: 'Djerf Avenue',
        },
        {
          key: 'Dolce & Gabbana',
          label: 'Dolce & Gabbana',
        },
        {
          key: 'Dr Martens',
          label: 'Dr Martens',
        },
        {
          key: 'Dylan Kain',
          label: 'Dylan Kain',
        },
        {
          key: 'Effie Kats',
          label: 'Effie Kats',
        },
        {
          key: 'Eliyah the Label',
          label: 'Eliyah the Label',
        },
        {
          key: 'Elka Colletive',
          label: 'Elka Colletive',
        },
        {
          key: 'Ellis Label',
          label: 'Ellis Label',
        },
        {
          key: 'Elliyat',
          label: 'Elliyat',
        },
        {
          key: 'Ena Pelly',
          label: 'Ena Pelly',
        },
        {
          key: 'Faithfull The Brand',
          label: 'Faithfull The Brand',
        },
        {
          key: 'Fame and Partners',
          label: 'Fame and Partners',
        },
        {
          key: 'Fendi',
          label: 'Fendi',
        },
        {
          key: 'Finders Keepers',
          label: 'Finders Keepers',
        },
        {
          key: 'For Love And Lemons',
          label: 'For Love And Lemons',
        },
        {
          key: 'Free People',
          label: 'Free People',
        },
        {
          key: 'Friends with Frank',
          label: 'Friends with Frank',
        },
        {
          key: 'Georgia Alice',
          label: 'Georgia Alice',
        },
        {
          key: 'Ginia',
          label: 'Ginia',
        },
        {
          key: 'Gucci',
          label: 'Gucci',
        },
        {
          key: 'Hansen & Gretel',
          label: 'Hansen & Gretel',
        },
        {
          key: 'House of CB',
          label: 'House of CB',
        },
        {
          key: 'Isabella Anslmi',
          label: 'Isabella Anslmi',
        },
        {
          key: 'Issy',
          label: 'Issy',
        },
        {
          key: 'JACQUEMUS',
          label: 'JACQUEMUS',
        },
        {
          key: 'Jagger & Stone',
          label: 'Jagger & Stone',
        },
        {
          key: 'James Smith',
          label: 'James Smith',
        },
        {
          key: 'Jimmy Choo',
          label: 'Jimmy Choo',
        },
        {
          key: 'Joslin',
          label: 'Joslin',
        },
        {
          key: 'Juliette Hogan',
          label: 'Juliette Hogan',
        },
        {
          key: 'Karen Walker',
          label: 'Karen Walker',
        },
        {
          key: 'Kate Spade',
          label: 'Kate Spade',
        },
        {
          key: 'Kate Sylvester',
          label: 'Kate Sylvester',
        },
        {
          key: 'Keepsake the Label',
          label: 'Keepsake the Label',
        },
        {
          key: 'Kendall Watt',
          label: 'Kendall Watt',
        },
        {
          key: 'Knuefermann',
          label: 'Knuefermann',
        },
        {
          key: 'Kookai',
          label: 'Kookai',
        },
        {
          key: 'kowtow',
          label: 'kowtow',
        },
        {
          key: 'Ksubi',
          label: 'Ksubi',
        },
        {
          key: "L'IDEE",
          label: "L'IDEE",
        },
        {
          key: "L'idee",
          label: "L'idee",
        },
        {
          key: 'Leo & Lin',
          label: 'Leo & Lin',
        },
        {
          key: "Levi's",
          label: "Levi's",
        },
        {
          key: 'Lexi',
          label: 'Lexi',
        },
        {
          key: 'Liam by Ruby',
          label: 'Liam by Ruby',
        },
        {
          key: 'Lioness',
          label: 'Lioness',
        },
        {
          key: 'Loewe',
          label: 'Loewe',
        },
        {
          key: 'Lonely Hearts ',
          label: 'Lonely Hearts ',
        },
        {
          key: 'Lululemon',
          label: 'Lululemon',
        },
        {
          key: 'Mackenzie Mode',
          label: 'Mackenzie Mode',
        },
        {
          key: 'Magali Pascal',
          label: 'Magali Pascal',
        },
        {
          key: 'Maggie Marilyn',
          label: 'Maggie Marilyn',
        },
        {
          key: 'Manning Cartell',
          label: 'Manning Cartell',
        },
        {
          key: 'Mara & Mine',
          label: 'Mara & Mine',
        },
        {
          key: 'Margot',
          label: 'Margot',
        },
        {
          key: 'Marle',
          label: 'Marle',
        },
        {
          key: 'Matteau',
          label: 'Matteau',
        },
        {
          key: 'Maurie & Eve',
          label: 'Maurie & Eve',
        },
        {
          key: 'Meadowlark',
          label: 'Meadowlark',
        },
        {
          key: 'Meshki',
          label: 'Meshki',
        },
        {
          key: 'Miaou',
          label: 'Miaou',
        },
        {
          key: 'Michael Hill Jewellery',
          label: 'Michael Hill Jewellery',
        },
        {
          key: 'Michael Kors',
          label: 'Michael Kors',
        },
        {
          key: 'Misha Collection',
          label: 'Misha Collection',
        },
        {
          key: 'Miss Crabb',
          label: 'Miss Crabb',
        },
        {
          key: 'Mon Renn',
          label: 'Mon Renn',
        },
        {
          key: 'Mossman',
          label: 'Mossman',
        },
        {
          key: 'Natalie Rolt',
          label: 'Natalie Rolt',
        },
        {
          key: 'New Romantics',
          label: 'New Romantics',
        },
        {
          key: 'Nicola Finette',
          label: 'Nicola Finette',
        },
        {
          key: 'Nike',
          label: 'Nike',
        },
        {
          key: 'Nobody Denim',
          label: 'Nobody Denim',
        },
        {
          key: 'Nookie',
          label: 'Nookie',
        },
        {
          key: 'One Fell Swoop',
          label: 'One Fell Swoop',
        },
        {
          key: 'One Mile the Label',
          label: 'One Mile the Label',
        },
        {
          key: 'One Teaspoon',
          label: 'One Teaspoon',
        },
        {
          key: 'Overose',
          label: 'Overose',
        },
        {
          key: 'Ownley',
          label: 'Ownley',
        },
        {
          key: 'Palm Noosa',
          label: 'Palm Noosa',
        },
        {
          key: 'Pandora',
          label: 'Pandora',
        },
        {
          key: 'Paris Georgia',
          label: 'Paris Georgia',
        },
        {
          key: 'Penny Sage',
          label: 'Penny Sage',
        },
        {
          key: 'Pera May',
          label: 'Pera May',
        },
        {
          key: 'Peter Alexander',
          label: 'Peter Alexander',
        },
        {
          key: 'Pia Boutique',
          label: 'Pia Boutique',
        },
        {
          key: 'Polo by Ralph Lauren',
          label: 'Polo by Ralph Lauren',
        },
        {
          key: 'Porter',
          label: 'Porter',
        },
        {
          key: 'Prada',
          label: 'Prada',
        },
        {
          key: 'Quay Eyewear',
          label: 'Quay Eyewear',
        },
        {
          key: 'R.M. Williams',
          label: 'R.M. Williams',
        },
        {
          key: 'Ralph Lauren',
          label: 'Ralph Lauren',
        },
        {
          key: 'Rat & Boa',
          label: 'Rat & Boa',
        },
        {
          key: 'Ray-Ban',
          label: 'Ray-Ban',
        },
        {
          key: 'Realisation Par',
          label: 'Realisation Par',
        },
        {
          key: 'Rebecca Valance',
          label: 'Rebecca Valance',
        },
        {
          key: 'Reformation',
          label: 'Reformation',
        },
        {
          key: 'Released From Love',
          label: 'Released From Love',
        },
        {
          key: 'Retrofete',
          label: 'Retrofete',
        },
        {
          key: 'Rollas',
          label: 'Rollas',
        },
        {
          key: 'Rotate',
          label: 'Rotate',
        },
        {
          key: 'RUBY',
          label: 'RUBY',
        },
        {
          key: 'Rumer',
          label: 'Rumer',
        },
        {
          key: 'S/W/F',
          label: 'S/W/F',
        },
        {
          key: 'Saint Armont',
          label: 'Saint Armont',
        },
        {
          key: 'Saint Laurent',
          label: 'Saint Laurent',
        },
        {
          key: 'sass & bide',
          label: 'sass & bide',
        },
        {
          key: 'Savannah Morrow',
          label: 'Savannah Morrow',
        },
        {
          key: 'Seafolly',
          label: 'Seafolly',
        },
        {
          key: 'Seed Heritage',
          label: 'Seed Heritage',
        },
        {
          key: 'Self Portrait',
          label: 'Self Portrait',
        },
        {
          key: 'Shona Joy',
          label: 'Shona Joy',
        },
        {
          key: 'Significant Other',
          label: 'Significant Other',
        },
        {
          key: 'Silk Laundry',
          label: 'Silk Laundry',
        },
        {
          key: 'SIR the label',
          label: 'SIR the label',
        },
        {
          key: 'Sisters the label',
          label: 'Sisters the label',
        },
        {
          key: 'Sofia The label',
          label: 'Sofia The label',
        },
        {
          key: 'Sovere Studio',
          label: 'Sovere Studio',
        },
        {
          key: 'Spell',
          label: 'Spell',
        },
        {
          key: 'Ssaint',
          label: 'Ssaint',
        },
        {
          key: 'St Cloud',
          label: 'St Cloud',
        },
        {
          key: 'Status Anxiety',
          label: 'Status Anxiety',
        },
        {
          key: 'Steele',
          label: 'Steele',
        },
        {
          key: 'Stolen Girlfriends Club ',
          label: 'Stolen Girlfriends Club ',
        },
        {
          key: 'Suboo',
          label: 'Suboo',
        },
        {
          key: 'Superette',
          label: 'Superette',
        },
        {
          key: 'SuperGa',
          label: 'SuperGa',
        },
        {
          key: 'Talulah La Maison',
          label: 'Talulah La Maison',
        },
        {
          key: 'The East Order',
          label: 'The East Order',
        },
        {
          key: 'The Wolf Gang',
          label: 'The Wolf Gang',
        },
        {
          key: 'Third Form',
          label: 'Third Form',
        },
        {
          key: 'Thurley',
          label: 'Thurley',
        },
        {
          key: 'Tiffany & Co.',
          label: 'Tiffany & Co.',
        },
        {
          key: 'Tony Bianco',
          label: 'Tony Bianco',
        },
        {
          key: 'Trelise Cooper',
          label: 'Trelise Cooper',
        },
        {
          key: 'Triangl',
          label: 'Triangl',
        },
        {
          key: 'Tussah the Label',
          label: 'Tussah the Label',
        },
        {
          key: 'Twenty-seven names',
          label: 'Twenty-seven names',
        },
        {
          key: 'Valentino',
          label: 'Valentino',
        },
        {
          key: 'Veja',
          label: 'Veja',
        },
        {
          key: "Victoria's Secret",
          label: "Victoria's Secret",
        },
        {
          key: 'Viktoria + Woods',
          label: 'Viktoria + Woods',
        },
        {
          key: 'Winona',
          label: 'Winona',
        },
        {
          key: 'With Jean',
          label: 'With Jean',
        },
        {
          key: 'Wrangler',
          label: 'Wrangler',
        },
        {
          key: 'Wynn Hamlyn',
          label: 'Wynn Hamlyn',
        },
        {
          key: 'Yu Mei ',
          label: 'Yu Mei ',
        },
        {
          key: 'Yves Saint Laurent',
          label: 'Yves Saint Laurent',
        },
        {
          key: 'Zimmerman',
          label: 'Zimmerman',
        },
        {
          key: 'Zoe & Morgan',
          label: 'Zoe & Morgan',
        },
      ],
    },
  },
  {
    id: 'occasion',
    label: 'Occasion',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_occasion'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      schemaType: 'multi-enum',
      searchMode: 'has_any',

      options: [
        { key: '21st', label: '21st' },
        { key: 'babyShower', label: 'Baby Shower' },
        { key: 'birthday', label: 'Birthday' },
        { key: 'blackTie', label: 'Black Tie' },
        { key: 'bridalShower', label: 'Bridal Shower' },
        { key: 'bridesmaid', label: 'Bridesmaid' },
        { key: 'cocktail', label: 'Cocktail' },
        { key: 'cocktailParty', label: 'Cocktail Party' },
        { key: 'dateNight', label: 'Date Night' },
        { key: 'daytimeEvent', label: 'Daytime Event' },
        { key: 'engagementParty', label: 'Engagement Party' },
        { key: 'eveningEvent', label: 'Evening Event' },
        { key: 'festival', label: 'Festival' },
        { key: 'formal', label: 'Formal' },
        { key: 'graduation', label: 'Graduation' },
        { key: 'hens', label: 'Hens' },
        { key: 'holiday', label: 'Holiday' },
        { key: 'nightOut', label: 'Night Out' },
        { key: 'party', label: 'Party' },
        { key: 'redCarpet', label: 'Red Carpet' },
        { key: 'theRaces', label: 'The Races' },
        { key: 'workFunction', label: 'Work Function' },
      ],
    },
  },
  {
    id: 'sizing',
    label: 'Sizing',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_sizing'],
    config: {
      schemaType: 'multi-enum',
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'osfa', label: 'OSFA' },
        { key: '4', label: '4' },
        { key: '6', label: '6' },
        { key: '8', label: '8' },
        { key: '10', label: '10' },
        { key: '12', label: '12' },
        { key: '14', label: '14' },
        { key: '16', label: '16' },
        { key: '18', label: '18' },
        { key: '20', label: '20' },
        { key: '22', label: '22' },
        { key: '24', label: '24' },
      ],
    },
  },
  {
    id: 'colours',
    label: 'Colours',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_colours'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      schemaType: 'multi-enum',
      searchMode: 'has_any',

      options: [
        { key: 'black', label: 'Black', hex: '#000000' },
        { key: 'brown', label: 'Brown', hex: '#964B00' },
        { key: 'purple', label: 'Purple', hex: '#A020F0' },
        { key: 'green', label: 'Green', hex: '#00FF00' },
        { key: 'khaki', label: 'Khaki', hex: '#C3B091' },
        { key: 'blue', label: 'Blue', hex: '#0000FF' },
        { key: 'turquoise', label: 'Turquoise', hex: '#40e0d0' },
        { key: 'red', label: 'Red', hex: '#FF0000' },
        { key: 'orange', label: 'Orange', hex: '#FFA500' },
        { key: 'pink', label: 'Pink', hex: '#FFC0CB' },
        { key: 'gold', label: 'Gold', hex: '#FFD700' },
        { key: 'yellow', label: 'Yellow', hex: '#FFFF00' },
        { key: 'grey', label: 'Grey', hex: '#808080' },
        { key: 'silver', label: 'Silver', hex: '#C0C0C0' },
        { key: 'beige', label: 'Beige', hex: '#F5F5DC' },
        { key: 'white', label: 'White', hex: '#FFFFFF' },
        { key: 'various', label: 'Various' },
      ],
    },
  },

  // customize here to push price filter pattern //TODO
  // add another one for rent, this is for buy
  {
    id: 'pricingBuy',
    label: 'Pricing',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_pricingBuy'],
    config: {
      // schemaType: 'multi-enum',
      // searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '30AndUnder', label: '$30 and under' },
        { key: '31to50', label: '$31 - $50' },
        { key: '51to100', label: '$51 - $100' },
        { key: '101to200', label: '$101 - $200' },
        { key: '201to500', label: '$201 - $500' },
      ],
    },
  },
  {
    id: 'pricingRent',
    label: 'Pricing',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_pricingRent'],
    config: {
      // schemaType: 'multi-enum',
      // searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '60AndUnder', label: '$60 and under' },
        { key: '61to100', label: '$61 - $100' },
        { key: '101to150', label: '$101 - $150' },
        { key: '151to200', label: '$151 - $200' },
        { key: '201andAbove', label: '$201 and above' },
      ],
    },
  },

  {
    id: 'style',
    label: 'Style',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_style'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'assymetic', label: 'Assymetic' },
        { key: 'biasCut', label: 'Bias Cut' },
        { key: 'bumpFriendly', label: 'Bump Friendly' },
        { key: 'classic', label: 'Classic' },
        { key: 'floral', label: 'Floral' },
        { key: 'highEnd', label: 'High End' },
        { key: 'lace', label: 'Lace' },
        { key: 'printed', label: 'Printed' },
        { key: 'summer', label: 'Summer' },
        { key: 'twoPiece', label: 'Two Piece' },
        { key: 'wrap', label: 'Wrap' },
      ],
    },
  },
  {
    id: 'neckline',
    label: 'Neckline',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_neckline'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'asummetric', label: 'Asummetric' },
        { key: 'cowl', label: 'Cowl' },
        { key: 'crew', label: 'Crew' },
        { key: 'haltter', label: 'Haltter' },
        { key: 'highNeck', label: 'High Neck' },
        { key: 'offShoulder', label: 'Off Shoulder' },
        { key: 'oneShoulder', label: 'One Shoulder' },
        { key: 'plunge', label: 'Plunge' },
        { key: 'round', label: 'Round' },
        { key: 'scoop', label: 'Scoop' },
        { key: 'strapless', label: 'Strapless' },
        { key: 'sweetheart', label: 'Sweetheart' },
        { key: 'swquare', label: 'Swquare' },
        { key: 'VNeck', label: 'V Neck' },
      ],
    },
  },
  {
    id: 'sleeve',
    label: 'Sleeve',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_sleeve'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '34Sleeve', label: '3/4 Sleeve' },
        { key: 'balloonSleeve', label: 'Balloon Sleeve' },
        { key: 'bellSelleve', label: 'Bell Selleve' },
        { key: 'capSleeve', label: 'Cap Sleeve' },
        { key: 'longSleeve', label: 'Long Sleeve' },
        { key: 'puffSLeeve', label: 'Puff SLeeve' },
        { key: 'shortSleeve', label: 'Short Sleeve' },
        { key: 'sleeveless', label: 'Sleeveless' },
        { key: 'strappy', label: 'Strappy' },
      ],
    },
  },
  // customize here to push location pattern //TODO
  {
    id: 'locationByRegion',
    label: 'Location by region',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_locationByRegion'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'auckland', label: 'Auckland' },
        { key: 'auckland', label: 'Bay of Plenty' },
        { key: 'auckland', label: 'Canterbury' },
        { key: 'auckland', label: 'Gisborne' },
        { key: 'auckland', label: "Hawke's Bay" },
        { key: 'auckland', label: 'Manawatū-Whanganui' },
        { key: 'auckland', label: 'Marlborough' },
        { key: 'auckland', label: 'Nelson' },
        { key: 'auckland', label: 'Northland' },
        { key: 'auckland', label: 'Otago' },
        { key: 'auckland', label: 'Southland' },
        { key: 'auckland', label: 'Taranaki' },
        { key: 'auckland', label: 'Tasman' },
        { key: 'auckland', label: 'Waikato' },
        { key: 'auckland', label: 'Wellington' },
        { key: 'auckland', label: 'West Coast' },
      ],
    },
  },
  {
    id: 'discount',
    label: 'Discount',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_discount'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all', label: 'All' },
        { key: '30andMore', label: '30% and more' },
        { key: '50andMore', label: '50% and more' },
        { key: '70andMore', label: '70% and more' },
        { key: '90andMore', label: '90% and more' },
      ],
    },
  },

  {
    id: 'condition',
    label: 'Condition',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_condition'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      schemaType: 'multi-enum',
      searchMode: 'has_any',
      options: [
        // { key: 'veryGood', label: 'Very good' },
        // { key: 'good', label: 'Good' },
        // { key: 'flawsListedInDescription', label: 'Flaws listed in description' },
        { key: 'preLoved', label: 'Used' },
        { key: 'newWithTags', label: 'New' },
      ],
    },
  },

  // {
  //   id: 'price',
  //   label: 'Price',
  //   type: 'PriceFilter',
  //   group: 'primary',
  //   // Note: PriceFilter is fixed filter,
  //   // you can't change "queryParamNames: ['price'],"
  //   queryParamNames: ['price'],
  //   // Price filter configuration
  //   // Note: unlike most prices this is not handled in subunits
  //   config: {
  //     min: 0,
  //     max: 1000,
  //     step: 5,
  //   },
  // },
  // {
  //   id: 'keyword',
  //   label: 'Keyword',
  //   type: 'KeywordFilter',
  //   group: 'primary',
  //   // Note: KeywordFilter is fixed filter,
  //   // you can't change "queryParamNames: ['keywords'],"
  //   queryParamNames: ['keywords'],
  //   // NOTE: If you are ordering search results by distance
  //   // the keyword search can't be used at the same time.
  //   // You can turn on/off ordering by distance from config.js file.
  //   config: {},
  // },

  /* {
    id: 'length',
    label: 'Length',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_length'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'mini', label: 'Mini' },
        { key: 'midi', label: 'Midi' },
        { key: 'maxi', label: 'Maxi' },
      ],
    },
  },
 */
  //   {
  //     id: 'pricing',
  //     label: 'Pricing',
  //     type: 'SelectSingleFilter',
  //     group: 'primary',
  //     queryParamNames: ['pub_pricing'],
  //     config: {
  //       // "key" is the option you see in Flex Console.
  //       // "label" is set here for the UI only.
  //       // Note: label is not added through the translation files
  //       // to make filter customizations a bit easier.
  //       options: [
  //         { key: '30andUnder', label: '$30 and under' },
  //         { key: '31to50', label: '$31 - $50' },
  //         { key: '51to100', label: '$51 - $100' },
  //         { key: '101to200', label: '$101 - $200' },
  //         { key: '201to500', label: '$201 - $500' },
  //         { key: '501andAbove', label: '$501 and above' },
  //       ],
  //     },
  //   },

  // {
  //   id: 'amenities',
  //   label: 'Amenities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_amenities'],
  //   config: {
  //     // Optional modes: 'has_all', 'has_any'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       {
  //         key: 'towels',
  //         label: 'Towels',
  //       },
  //       {
  //         key: 'bathroom',
  //         label: 'Bathroom',
  //       },
  //       {
  //         key: 'swimming_pool',
  //         label: 'Swimming pool',
  //       },
  //       {
  //         key: 'own_drinks',
  //         label: 'Own drinks allowed',
  //       },
  //       {
  //         key: 'jacuzzi',
  //         label: 'Jacuzzi',
  //       },
  //       {
  //         key: 'audiovisual_entertainment',
  //         label: 'Audiovisual entertainment',
  //       },
  //       {
  //         key: 'barbeque',
  //         label: 'Barbeque',
  //       },
  //       {
  //         key: 'own_food_allowed',
  //         label: 'Own food allowed',
  //       },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
