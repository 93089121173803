import React, { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import css from './TopbarDesktop.module.css';

function HoverDropdown(props) {
  const { label, columns } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div>
      <p
        onMouseEnter={() => setDropdownOpen(true)}
        onMouseLeave={() => setDropdownOpen(false)}
        className={css.dropdownLabel}
      >
        {label} <KeyboardArrowDownIcon />
      </p>
      <div
        className={dropdownOpen ? css.menu : css.menuHidden}
        onMouseEnter={() => setDropdownOpen(true)}
        onMouseLeave={() => setDropdownOpen(false)}
      >
        {columns.map(c => {
          return (
            <div className={css.column}>
              {c.header ? <p>{c.header} </p> : <br />}
              {c.items.map(i => {
                return <a href={i.path}>{i.label}</a>;
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default HoverDropdown;
