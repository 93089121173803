import React, { Component, useState, useRef } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { nonEmptyArray, composeValidators } from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import { AddImages, Button, Form, ValidationError, Modal } from '../../components';
import FieldToogle from '../../components/FieldToogle/FieldToogle';
import css from './EditListingPhotosForm.module.css';

// -----------------------------  crop START -----------------------------
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import Compress from 'compress.js';
const compress = new Compress();

// -----------------------------  crop END -----------------------------

const ACCEPT_IMAGES = 'image/*';

export class EditListingPhotosFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { imageUploadRequested: false };
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.submittedImages = [];
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      require('react-image-crop/dist/ReactCrop.css');
    }
  }

  onImageUploadHandler(file) {
    if (file) {
      this.setState({ imageUploadRequested: true });
      this.props
        .onImageUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          this.setState({ imageUploadRequested: false });
        })
        .catch(() => {
          this.setState({ imageUploadRequested: false });
        });
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        onImageUploadHandler={this.onImageUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        initialValues={{ images: this.props.images, isProductForSale: this.props.isProductForSale }}
        render={formRenderProps => {
          const {
            form,
            className,
            fetchErrors,
            handleSubmit,
            images,
            imageUploadRequested,
            intl,
            invalid,
            onImageUploadHandler,
            onRemoveImage,
            disabled,
            ready,
            saveActionMsg,
            updated,
            updateInProgress,
            panelTitle,
            values,
          } = formRenderProps;

          const toogleLabel =
            values.isProductForSale && values.isProductForSale[0] === 'true'
              ? 'For sale'
              : 'For rent';

          const chooseImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>
                <FormattedMessage id="EditListingPhotosForm.chooseImage" />
              </span>
              <span className={css.imageTypes}>
                <FormattedMessage id="EditListingPhotosForm.imageTypes" />
              </span>
            </span>
          );

          const imageRequiredMessage = intl.formatMessage({
            id: 'EditListingPhotosForm.imageRequired',
          });

          const { publishListingError, showListingsError, updateListingError, uploadImageError } =
            fetchErrors || {};
          const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

          let uploadImageFailed = null;

          if (uploadOverLimit) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
              </p>
            );
          } else if (uploadImageError) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
              </p>
            );
          }

          // NOTE: These error messages are here since Photos panel is the last visible panel
          // before creating a new listing. If that order is changed, these should be changed too.
          // Create and show listing errors are shown above submit button
          const publishListingFailed = publishListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
            </p>
          ) : null;
          const showListingFailed = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
            </p>
          ) : null;

          const submittedOnce = this.submittedImages.length > 0;
          // imgs can contain added images (with temp ids) and submitted images with uniq ids.
          const arrayOfImgIds = imgs =>
            imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
          const imageIdsFromProps = arrayOfImgIds(images);
          const imageIdsFromPreviousSubmit = arrayOfImgIds(this.submittedImages);
          const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
          const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

          const submitReady = (updated && pristineSinceLastSubmit) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled =
            invalid || disabled || submitInProgress || imageUploadRequested || ready;
          console.log(values);
          const classes = classNames(css.root, className);
          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedImages = images;
                handleSubmit(e);
              }}
            >
              <div className={css.headingWrapper}>
                {panelTitle}
                <FieldToogle id="isProductForSale" name={'isProductForSale'} label={toogleLabel} />
              </div>
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingPhotosForm.updateFailed" />
                </p>
              ) : null}
              <AddImages
                className={css.imagesField}
                images={images}
                thumbnailClassName={css.thumbnail}
                savedImageAltText={intl.formatMessage({
                  id: 'EditListingPhotosForm.savedImageAltText',
                })}
                onRemoveImage={onRemoveImage}
              >
                <Field
                  id="addImage"
                  name="addImage"
                  accept={ACCEPT_IMAGES}
                  form={null}
                  label={chooseImageText}
                  type="file"
                  disabled={imageUploadRequested}
                >
                  {fieldprops => {
                    const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                    const { name, type } = input;

                    // -----------------------------  crop START -----------------------------
                    function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
                      return centerCrop(
                        makeAspectCrop(
                          {
                            unit: '%',
                            width: 90,
                          },
                          aspect,
                          mediaWidth,
                          mediaHeight
                        ),
                        mediaWidth,
                        mediaHeight
                      );
                    }

                    const [imgSrc, setImgSrc] = useState('');
                    const previewCanvasRef = useRef(null);
                    const imgRef = useRef(null);
                    const [crop, setCrop] = useState({
                      unit: 'px', // Can be 'px' or '%'
                      x: 25,
                      y: 25,
                      width: 80,
                      height: 120,
                      aspect: 5 / 8,
                    });
                    const [completedCrop, setCompletedCrop] = useState();
                    const [scale, setScale] = useState(1);
                    const [rotate, setRotate] = useState(0);
                    const [aspect, setAspect] = useState(5 / 8);
                    const [blobFile, setBlobFile] = useState(null);
                    const [cropModalOpen, setCropModalOpen] = useState(false);
                    const [loadingCrop, setLoadingCrop] = useState(false);

                    // -----------------------------  crop END -----------------------------

                    const onChange = e => {
                      const file = e.target.files[0];

                      form.change(`addImage`, file);
                      form.blur(`addImage`);
                      onImageUploadHandler(file);

                      // {
                      //   /* -----------------------------  crop START  ----------------------------- */
                      // }
                      // return compress
                      //   .compress([e.target.files[0]], {
                      //     size: 1, // the max size in MB, defaults to 2MB
                      //     quality: 0.5, // the quality of the image, max is 1,
                      //     maxWidth: 1920, // the max width of the output image, defaults to 1920px
                      //     maxHeight: 1920, // the max height of the output image, defaults to 1920px
                      //     resize: true, // defaults to true, set false if you do not want to resize the image width and height
                      //     rotate: false, // See the rotation section below
                      //   })
                      //   .then(data => {
                      //     const base64str = data[0].data;
                      //     const imgExt = data[0].ext;
                      //     const file = Compress.convertBase64ToFile(base64str, imgExt);
                      //     setCrop(undefined); // Makes crop preview update between images.
                      //     const reader = new FileReader();
                      //     reader.addEventListener('load', () =>
                      //       setImgSrc(reader.result.toString() || '')
                      //     );
                      //     reader.readAsDataURL(file);

                      //     setCropModalOpen(true);
                      //   });

                      // {
                      //   /* -----------------------------  crop END ----------------------------- */
                      // }
                    };

                    {
                      /* -----------------------------  crop START ----------------------------- */
                    }

                    function onImageLoad(e) {
                      if (aspect) {
                        const { width, height } = e.currentTarget;
                        setCrop({
                          unit: 'px', // Can be 'px' or '%'
                          x: 25,
                          y: 25,
                          width: 80,
                          height: 120,
                          aspect: 5 / 8,
                        });

                        setTimeout(() => {
                          if (previewCanvasRef.current) {
                            var canvas = document.getElementById('previewCanvas');

                            canvas.toBlob(function(blob) {
                              setBlobFile(blob);
                            });
                          }
                        }, 1000);
                      }
                    }

                    useDebounceEffect(
                      async () => {
                        if (
                          completedCrop?.width &&
                          completedCrop?.height &&
                          imgRef.current &&
                          previewCanvasRef.current
                        ) {
                          // We use canvasPreview as it's much faster than imgPreview.
                          canvasPreview(
                            imgRef.current,
                            previewCanvasRef.current,
                            completedCrop,
                            scale,
                            rotate
                          );
                        }
                      },
                      100,
                      [completedCrop, scale, rotate]
                    );

                    const handleCompletedCrop = c => {
                      setCompletedCrop(c);

                      setTimeout(() => {
                        if (previewCanvasRef.current) {
                          var canvas = document.getElementById('previewCanvas');

                          canvas.toBlob(function(blob) {
                            setBlobFile(blob);
                            setLoadingCrop(false);
                          });
                        }
                      }, 1000);
                    };

                    const onSave = () => {
                      const newImage = new File([blobFile], 'newImage', { type: blobFile.type });
                      form.change(`addImage`, newImage);
                      form.blur(`addImage`);
                      onImageUploadHandler(newImage);
                      setCropModalOpen(false);
                    };

                    {
                      /* -----------------------------  crop END ----------------------------- */
                    }
                    const inputProps = { accept, id: name, name, onChange, type };
                    return (
                      <div className={css.addImageWrapper}>
                        <div className={css.aspectRatioWrapper}>
                          {fieldDisabled ? null : (
                            <input {...inputProps} className={css.addImageInput} />
                          )}
                          <label htmlFor={name} className={css.addImage}>
                            {label}
                          </label>
                        </div>

                        {/* -----------------------------  crop START ----------------------------- */}

                        <Modal
                          isOpen={cropModalOpen}
                          onClose={() => {
                            setCropModalOpen(false);
                          }}
                          onManageDisableScrolling={() => {}}
                        >
                          <div className={css.modalContentWrapper}>
                            <div className={css.modalContentTopSection}>
                              <div className={css.croppingToolWrapper}>
                                <h2>Edit</h2>

                                <ReactCrop
                                  crop={crop}
                                  onChange={(_, percentCrop) => {
                                    setLoadingCrop(true);
                                    setCrop(percentCrop);
                                  }}
                                  onComplete={handleCompletedCrop}
                                  aspect={aspect}
                                >
                                  <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSrc}
                                    style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                    onLoad={onImageLoad}
                                  />
                                </ReactCrop>
                              </div>

                              <div className={css.previewWrapper}>
                                <h2 className={css.previewCanvas}>Preview</h2>

                                {completedCrop && (
                                  <canvas
                                    id="previewCanvas"
                                    ref={previewCanvasRef}
                                    style={{
                                      border: '1px solid black',
                                      objectFit: 'contain',
                                      width: '100%', //completedCrop.width,
                                      height: '100%', //completedCrop.height,
                                    }}
                                    className={css.previewCanvas}
                                  />
                                )}
                              </div>
                            </div>

                            <div
                              className={
                                blobFile && !loadingCrop ? css.saveButton : css.saveButtonDisabled
                              }
                              onClick={blobFile && !loadingCrop ? onSave : () => {}}
                            >
                              Add
                            </div>
                          </div>
                        </Modal>

                        {/* -----------------------------  crop END ----------------------------- */}
                      </div>
                    );
                  }}
                </Field>

                <Field
                  component={props => {
                    const { input, meta } = props;
                    return (
                      <div className={css.imageRequiredWrapper}>
                        <input {...input} />
                        <ValidationError fieldMeta={meta} />
                      </div>
                    );
                  }}
                  name="images"
                  type="hidden"
                  validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
                />
              </AddImages>
              {uploadImageFailed}

              <p className={css.tip}>
                <FormattedMessage id="EditListingPhotosForm.addImagesTip" />
              </p>
              {publishListingFailed}
              {showListingFailed}

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingPhotosFormComponent.defaultProps = { fetchErrors: null, images: [] };

EditListingPhotosFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default compose(injectIntl)(EditListingPhotosFormComponent);
