import React, { useState, useEffect, useMemo } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import { getValuesFromQueryString } from '../../util/urlHelpers';
import config from '../../config';

import css from './EditListingDescriptionPanel.module.css';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const [host, setHost] = useState(false);

  useEffect(() => {
    sdk.currentUser
      .show()
      .then(res => {
        if (res.data.data) {
          setHost(res.data.data);
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  const currentListing = ensureOwnListing(listing);

  const queryStringValues = getValuesFromQueryString();

  const isProduct =
    queryStringValues?.isProductForSale === 'true' ||
    currentListing?.attributes?.publicData?.isProductForSale === 'true' ||
    (Array.isArray(currentListing?.attributes?.publicData?.isProductForSale) &&
      currentListing?.attributes?.publicData?.isProductForSale[0] === 'true');
  const isProductForSale = isProduct
    ? { isProductForSale: ['true'] }
    : { isProductForSale: ['false'] };

  const classes = classNames(rootClassName || css.root, className);
  const { description, title, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );
  const brand = publicData && publicData.designer;
  const sizing = publicData && publicData.sizing;
  const colours = publicData && publicData.colours;
  const condition = publicData && publicData.condition;
  const occasion = publicData && publicData.occasion;
  const category = publicData && publicData.category;
  const subcategory = publicData && publicData.subcategory;
  const style = publicData && publicData.style;
  const material = publicData && publicData.material;
  const madeIn = publicData && publicData.madeIn;
  const mesurements = publicData && publicData.mesurements;

  const initialValues = useMemo(
    () => ({
      brand: brand ? brand[0] : brand,
      sizing: sizing ? sizing[0] : sizing,
      colours,
      condition: condition ? condition[0] : condition,
      occasion: occasion ? occasion[0] : occasion,
      category: category ? category[0] : category,
      subcategory: subcategory ? subcategory[0] : subcategory,
      style,
      material,
      madeIn,
      mesurements,
      ...isProductForSale,
    }),
    []
  );

  return (
    <div className={classes}>
      <EditListingDescriptionForm
        className={css.form}
        initialValues={{ title, description, ...initialValues }}
        initialValuesEqual={() => true}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            title,
            description,
            brand,
            sizing,
            colours,
            condition,
            occasion,
            category,
            subcategory,
            isProductForSale,
            style,
            material,
            madeIn,
            mesurements,
          } = values;

          const hostIdObj = host
            ? {
                hostId: host.id.uuid,
              }
            : {};

          const updateValues = {
            title: title.trim(),
            description,
            publicData: {
              isProductForSale,
              ...hostIdObj,
              designer: [brand],
              sizing: [sizing],
              colours,
              condition: [condition],
              occasion: [occasion],
              category: [category],
              subcategory: [subcategory],
              style,
              material,
              madeIn,
              mesurements,
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        panelTitle={<h1 className={css.title}>{panelTitle}</h1>}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
