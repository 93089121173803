import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
  txIsOfferAccepted,
  txIsOfferDeclined,
  txIsOfferPending,
  txIsInitialOffer,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  AvatarLarge,
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
  PrimaryButton,
  SecondaryButton,
  Modal,
  Button,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';
import { createSlug } from '../../util/urlHelpers';
// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
  HEADING_INITIAL_OFFER,
  HEADING_OFFER_ACCEPTED,
  HEADING_OFFER_DECLINED,
  HEADING_OFFER_PENDING,
} from './PanelHeading';
import { pushToPath } from '../../util/urlHelpers';
import css from './TransactionPanel.module.css';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      counterOfferModalOpen: false,
      newOfferValue: null,
      sameVendorWarningModalOpen: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      timeSlots,
      fetchTimeSlotsError,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      onCancelSale,

      onAcceptSwap,
      onDeclineSwap,
      isDonationListing,
      onAcceptOffer,
      onDeclineOffer,
      onCounterOffer,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const stateDataFn = tx => {
      const lastTransition = tx && tx?.attributes?.lastTransition;
      const isSwapRequest = lastTransition === 'transition/swap-request';

      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
              return transition.attributes.name;
            })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: false, //isCustomer && !isProviderBanned && hasCorrectNextTransition,
          personalDetailsWarning: true,
          showAcceptDeclineSwapButtons: isSwapRequest && isProvider,
        };
      } else if (txIsInitialOffer(tx)) {
        return {
          headingState: HEADING_INITIAL_OFFER,
          showDetailCardHeadings: isCustomer,
          showOfferActionsButtons: isProvider,
          showOfferSendMessage: isCustomer,
        };
      } else if (txIsOfferAccepted(tx)) {
        return {
          headingState: HEADING_OFFER_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAcceptedOfferMessage: isProvider,
          showBookingPanel: isCustomer,
        };
      } else if (txIsOfferDeclined(tx)) {
        return {
          headingState: HEADING_OFFER_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsOfferPending(tx)) {
        return {
          headingState: HEADING_OFFER_PENDING,
          showDetailCardHeadings: isCustomer,
          showOfferActionsButtons:
            lastTransition === 'transition/counter-offer-by-provider' ||
            lastTransition === 'transition/counter-pending-offer-by-provider'
              ? isCustomer
              : isProvider,
          showOfferSendMessage:
            lastTransition === 'transition/counter-offer-by-provider' ||
            lastTransition === 'transition/counter-pending-offer-by-provider'
              ? isProvider
              : isCustomer,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const isProductForSale =
      Array.isArray(publicData?.isProductForSale) && publicData?.isProductForSale[0] === 'true';
    const unitTranslationKey = isProductForSale
      ? 'TransactionPanel.perUnit'
      : isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => onAcceptSale(currentTransaction)}
        onDeclineSale={() => onDeclineSale(currentTransaction.id)}
      />
    );

    const swapButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showAcceptDeclineSwapButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => onAcceptSwap(currentTransaction)}
        onDeclineSale={() => onDeclineSwap(currentTransaction.id)}
        rootClassName={css.swapButtons}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);

    const rawCurrentDate = new Date();
    // const currentDate = rawCurrentDate.getDate();
    // const cancellationLimit = currentTransaction.listing.attributes.publicData.cancelLimit;
    const rawStartDate = currentTransaction.booking?.attributes?.displayStart;
    // const rawEndDate = currentTransaction.booking.attributes.displayEnd;
    // const bookMonth = rawStartDate.getMonth();
    // const currentMonth = rawCurrentDate.getMonth();
    // const startBookDate = rawStartDate.getDate();
    // const endBookDate = rawEndDate.getDate();

    // const testBookDate = 1;
    // const testCurrentDate = 31;
    // const testBookMonth = 5;
    // const testCurrrentMonth = 3;

    const hours = Math.abs(rawCurrentDate - rawStartDate) / 36e5;
    const isButtonDisabled = hours < 336 ? true : false;

    // const isButtonDisabled =  (startBookDate - currentDate > 100) && (bookMonth - currentMonth <= 1) ? true : false;

    const lastTransitionAcceptOrConfirm =
      currentTransaction.attributes.lastTransition == 'transition/accept' ||
      currentTransaction.attributes.lastTransition == 'transition/confirm-payment';

    const restOfShoppingCartItems = currentTransaction?.attributes.metadata.restOfShoppingCartItems
      ? currentTransaction?.attributes.metadata.restOfShoppingCartItems.map(item => {
          return JSON.parse(item);
        })
      : false;
    const lastTransition = currentTransaction && currentTransaction.attributes.lastTransition;
    const currentTransactionProtectedData =
      currentTransaction && currentTransaction?.attributes?.protectedData;

    const proposedPrice =
      currentTransactionProtectedData && currentTransactionProtectedData.proposedPrice;

    const handleCounterOffer = () => {
      // const role = isCustomer ? 'customer' : 'provider';
      // return onCounterOffer(role, currentTransaction)
      this.setState({
        counterOfferModalOpen: true,
      });
    };

    const handleAcceptOffer = () => {
      const role = isCustomer ? 'customer' : 'provider';
      return onAcceptOffer(role, currentTransaction);
    };

    const handleDeclineOffer = () => {
      const role = isCustomer ? 'customer' : 'provider';
      return onDeclineOffer(role, currentTransaction);
    };

    if (proposedPrice && currentListing && currentListing?.attributes?.price) {
      currentListing.attributes.price.amount = Number(proposedPrice) * 100;
    }

    const onSubmitProduct = values => {
      if (proposedPrice) {
        values.proposedPrice = proposedPrice;
      }

      return sdk.currentUser
        .show()
        .then(res => {
          const currentShoppingCart = res.data.data.attributes.profile.publicData.shoppingCart
            ? res.data.data.attributes.profile.publicData.shoppingCart
            : [];
          const currentShoppingCartUnwrapped = currentShoppingCart.map(item => {
            return {
              listing: JSON.parse(item.listing),
              checkoutValues: JSON.parse(item.checkoutValues),
            };
          });

          const isFromSameVendor =
            currentShoppingCartUnwrapped.length === 0 ||
            currentShoppingCartUnwrapped.find(item => {
              return item.listing.author.id.uuid === currentListing.author.id.uuid;
            });

          if (isFromSameVendor) {
            let currentListingCopy = { ...currentListing };

            //ADD TO CART

            const shoppingCartItem = {
              listing: JSON.stringify({ ...currentListingCopy }),
              checkoutValues: JSON.stringify({ ...values }),
            };

            return sdk.currentUser
              .updateProfile({
                publicData: {
                  shoppingCart: [...currentShoppingCart, shoppingCartItem],
                },
              })
              .then(resp => {
                if (typeof window !== 'undefined') {
                  window.location.reload();
                } else {
                  return true;
                }
              })
              .catch(e => console.log(e));
          } else {
            this.setState({
              sameVendorWarningModalOpen: true,
            });
          }
        })
        .catch(e => console.log(e));
    };

    const clearBasket = () => {
      return sdk.currentUser
        .updateProfile({
          publicData: {
            shoppingCart: [],
          },
        })
        .then(res => {
          return window.location.reload();
        })
        .catch(e => console.log(e));
    };

    const swapListing = currentTransaction?.attributes?.protectedData?.swapListing;

    let sideInfo = (
      <div className={css.detailCard}>
        {restOfShoppingCartItems ? null : (
          <DetailCardImage
            avatarWrapperClassName={css.avatarWrapperDesktop}
            listingTitle={listingTitle}
            image={firstImage}
            provider={currentProvider}
            isCustomer={isCustomer}
            isDonation={isDonationListing}
          />
        )}

        {restOfShoppingCartItems || isDonationListing ? null : (
          <DetailCardHeadingsMaybe
            showDetailCardHeadings={stateData.showDetailCardHeadings}
            listingTitle={listingTitle}
            subTitle={bookingSubTitle}
            location={location}
            geolocation={geolocation}
            showAddress={stateData.showAddress}
          />
        )}

        {/* info to show to customer after he made an offer */}

        {stateData?.showOfferSendMessage ? (
          <div className={css.offerInfoWrapper}>
            <center>
              <h2>You made an offer for AUD ${proposedPrice}</h2>
            </center>
            <center>
              <p>
                Wait for {isCustomer ? authorDisplayName : customerDisplayName} to accept or decline
                your offer
              </p>
            </center>
          </div>
        ) : null}

        {stateData.showAcceptedOfferMessage ? (
          <div className={css.offerInfoWrapper}>
            <center>
              <h2>The offer for AUD ${proposedPrice} was accepted</h2>
            </center>
            <center>
              <p>Wait for {customerDisplayName} to make the payment</p>
            </center>
          </div>
        ) : null}

        {/* info to show to provider after customer made the first an offer */}

        {stateData?.showOfferActionsButtons ? (
          <div className={css.offerInfoWrapper}>
            <center>
              <h2>
                {isProvider ? customerDisplayName : authorDisplayName} offered AUD ${proposedPrice}
              </h2>
            </center>
            <PrimaryButton
              type="button"
              className={css.offerActionButton}
              onClick={handleAcceptOffer}
            >
              Accept
            </PrimaryButton>
            <PrimaryButton
              type="button"
              className={css.offerActionButton}
              onClick={handleCounterOffer}
            >
              Counter
            </PrimaryButton>
            <SecondaryButton
              type="button"
              className={css.offerActionButton}
              onClick={handleDeclineOffer}
            >
              Decline
            </SecondaryButton>
          </div>
        ) : null}

        {stateData.showBookingPanel ? (
          <BookingPanel
            className={css.bookingPanel}
            titleClassName={css.bookingTitle}
            isOwnListing={false}
            listing={currentListing}
            title={listingTitle}
            subTitle={bookingSubTitle}
            authorDisplayName={authorDisplayName}
            onSubmit={isProductForSale ? onSubmitProduct : onSubmitBookingRequest}
            onManageDisableScrolling={onManageDisableScrolling}
            timeSlots={timeSlots}
            fetchTimeSlotsError={fetchTimeSlotsError}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            isProductForSale={isProductForSale}
            fromTransactionPage={true}
            proposedPrice={proposedPrice}
          />
        ) : null}
        <BreakdownMaybe
          className={css.breakdownContainer}
          transaction={currentTransaction}
          transactionRole={transactionRole}
          restOfShoppingCartItems={restOfShoppingCartItems}
          isDonation={isDonationListing}
        />

        {stateData.showSaleButtons ? (
          <div className={css.desktopActionButtons}>{saleButtons}</div>
        ) : null}
      </div>
    );

    const currentListingImageUrl =
      firstImage?.attributes && firstImage?.attributes?.variants['default']?.url;

    if (swapListing && currentListing) {
      sideInfo = (
        <div className={css.swapWrapper}>
          <div className={css.swapListingsInfoWrapper}>
            <NamedLink
              className={css.swapListingCard}
              name="ListingPage"
              params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle) }}
            >
              <img src={currentListingImageUrl} className={css.swapImage} />
              <p className={css.swapListingTitle}>{listingTitle}</p>
            </NamedLink>
            <SwapHorizIcon className={css.swapIcon} />
            <NamedLink
              className={css.swapListingCard}
              name="ListingPage"
              params={{ id: swapListing.id, slug: createSlug(swapListing.title) }}
            >
              <img src={swapListing.imageUrl} className={css.swapImage} />
              <p className={css.swapListingTitle}>{swapListing.title}</p>
            </NamedLink>
          </div>
          {stateData.showAcceptDeclineSwapButtons ? (
            <div className={css.desktopActionButtons}>{swapButtons}</div>
          ) : null}
        </div>
      );
    }

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              provider={currentProvider}
              isCustomer={isCustomer}
            />
            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null}

            <PanelHeading
              panelHeadingState={isDonationListing ? 'donation' : stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
              isProductForSale={isProductForSale}
              currentTransaction={currentTransaction}
            />

            <div className={css.bookingDetailsMobile}>
              <AddressLinkMaybe
                rootClassName={css.addressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              <BreakdownMaybe transaction={currentTransaction} transactionRole={transactionRole} />
            </div>

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}

            {!isDonationListing && (
              <FeedSection
                rootClassName={css.feedContainer}
                currentTransaction={currentTransaction}
                currentUser={currentUser}
                fetchMessagesError={fetchMessagesError}
                fetchMessagesInProgress={fetchMessagesInProgress}
                initialMessageFailed={initialMessageFailed}
                messages={messages}
                oldestMessagePageFetched={oldestMessagePageFetched}
                onOpenReviewModal={this.onOpenReviewModal}
                onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
                totalMessagePages={totalMessagePages}
                isProductForSale={isProductForSale}
              />
            )}

            {/* {!isProvider && lastTransitionAcceptOrConfirm ? (
              <div className={css.submitContainer} style={{ margin: 30 }}>
                <SecondaryButton

                    // rootClassName={css.submitButton}
                    rootClassName={isButtonDisabled ? css.cancelButtonDisabled : css.cancelButton}
                    onClick={() => onCancelSale(currentTransaction.id)} 
                  >

                    Cancel & get full refund
                  </SecondaryButton>
              </div>)
            :null} */}

            {!isDonationListing &&
              (showSendMessageForm ? (
                <SendMessageForm
                  formId={this.sendMessageFormName}
                  rootClassName={css.sendMessageForm}
                  messagePlaceholder={sendMessagePlaceholder}
                  inProgress={sendMessageInProgress}
                  sendMessageError={sendMessageError}
                  onFocus={this.onSendMessageFormFocus}
                  onBlur={this.onSendMessageFormBlur}
                  onSubmit={this.onMessageSubmit}
                  personalDetailsWarning={stateData.personalDetailsWarning}
                />
              ) : (
                <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
              ))}

            {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}
          </div>

          <div className={css.asideDesktop}>{sideInfo}</div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />

        {/* Counter offer modal */}
        <Modal
          isOpen={this.state.counterOfferModalOpen}
          onClose={() => {
            this.setState({
              counterOfferModalOpen: false,
            });
          }}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.offerModalWrapper}>
            <center>
              <h2>Make a counter offer</h2>
            </center>
            <div className={css.offerModalFieldWrapper}>
              <p>Your offer in AUD</p>
              <input
                type="number"
                placeholder="50"
                className={css.offerModalField}
                min={1}
                value={this.state.offerValue}
                onChange={e => {
                  return this.setState({
                    newOfferValue: e.target.value,
                  });
                }}
              />

              <PrimaryButton
                type="button"
                className={css.submutOfferButton}
                disabled={!this.state.newOfferValue && this.state.newOfferValue < 1}
                onClick={() => {
                  const role = isCustomer ? 'customer' : 'provider';
                  return onCounterOffer(role, currentTransaction, this.state.newOfferValue).then(
                    resp => {
                      this.setState({ counterOfferModalOpen: false });
                    }
                  );
                }}
              >
                Submit counter offer
              </PrimaryButton>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.sameVendorWarningModalOpen}
          onClose={() => {
            this.setSameVendorWarningModalOpen(false);
          }}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <center>
            <h2>
              <FormattedMessage id="ListingPage.sameVendorModalTitle" />
            </h2>
          </center>

          <div className={css.modalButtonsWrapper}>
            <Button type="button" className={css.modalButton} onClick={clearBasket}>
              <FormattedMessage id="ListingPage.clearBasket" />
            </Button>

            <Button
              type="button"
              className={css.modalButton}
              onClick={() =>
                pushToPath(`/s?pub_hostId=${hostIdOfFirstItem}&pub_isProductForSale=true`)
              }
            >
              <FormattedMessage id="ListingPage.seeSameVendorListings" />
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
