import React, { useState, useEffect } from 'react';
import Notifications from "react-notifications-menu";
import NotificationsIcon from '@mui/icons-material/Notifications';
import css from './NotificationsMenu.css';

function NotificationsMenu(props) {

    const {currentUser} = props;
   
    const [notificationsData, setNotificationsData] = useState([])

    useEffect(() => {
        if(currentUser && currentUser?.attributes?.profile){
            setNotificationsData(currentUser?.attributes?.profile.publicData.notifications ?? [])
        }

    }, [currentUser])

  return (
    <div className='notificationsBellWrapper'> 
    <Notifications
        data={notificationsData}
        header={{
            title: 'Notifications',
            option: {},
        }}
        markAsRead={(data) => {
            console.log('ceva')
            return data.read
          }}
        // classNamePrefix="okrjoy"
        //    icon={"https://sharetribe.imgix.net/620e9ea6-fe18-4a5c-a12d-63db5dc5893f/621e4628-cef9-4e19-b805-6e1f5bd59628?auto=format&crop=edges&fit=crop&h=240&w=240&s=b91778c978824902dcb56d84f8d0aed2"}
        />
    </div>
  )
}

export default NotificationsMenu