import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';

const UserNav = props => {
  const { className, rootClassName, selectedPageName, options } = props;
  const classes = classNames(rootClassName || css.root, className);

  let tabs = [
    {
      text: <FormattedMessage id="ManageListingsPage.profileSettings" />,
      selected: selectedPageName === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
      id: 'ProfileSettingsPage',
    },
    {
      text: <FormattedMessage id="ManageListingsPage.MyListings" />,
      selected: selectedPageName === 'MyListings',
      linkProps: {
        name: 'ManageListingsPage',
      },
      id: 'MyListings',
    },
    // {
    //   text: <FormattedMessage id="ManageListingsPage.MyRotationgWardrobe" />,
    //   selected: selectedPageName === 'MyRotationgWardrobe',
    //   linkProps: {
    //     name: 'ManageListingsPage',
    //   },
    //   id: 'MyRotationgWardrobe',
    // },
    {
      text: <FormattedMessage id="ManageListingsPage.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
      id: 'ContactDetailsPage',
    },
    // {
    //   text: <FormattedMessage id="ManageListingsPage.FavListingsPage" />,
    //   selected: selectedPageName === 'FavListingsPage',
    //   disabled: false,
    //   linkProps: {
    //     name: 'FavListingsPage',
    //   },
    //   id: 'FavListingsPage',
    // },
    {
      text: <FormattedMessage id="ManageListingsPage.InboxPage" />,
      selected: selectedPageName === 'InboxPageSales',
      disabled: false,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'sales' },
      },
      id: 'InboxPageSales',
    },
    {
      text: <FormattedMessage id="ManageListingsPage.InboxPageOrders" />,
      selected: selectedPageName === 'InboxPageOrders',
      disabled: false,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'orders' },
      },
      id: 'InboxPageOrders',
    },

    // {
    //   text: <FormattedMessage id="ManageListingsPage.FollowersPage" />,
    //   selected: selectedPageName === 'FollowersPage',
    //   disabled: false,
    //   linkProps: {
    //     name: 'FollowersPage',
    //   },
    //   id: 'FollowersPage',
    // },

    // {
    //   text: <FormattedMessage id="ManageListingsPage.ReferralPage" />,
    //   selected: selectedPageName === 'ReferralPage',
    //   disabled: false,
    //   linkProps: {
    //     name: 'ReferralPage',
    //   },
    //   id: 'ReferralPage',
    // },

    {
      text: <FormattedMessage id="ManageListingsPage.SettingsPage" />,
      selected: selectedPageName === 'SettingsPage',
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
      id: 'SettingsPage',
    },

    // {
    //   text: <FormattedMessage id="ManageListingsPage.MasterCalendarPage" />,
    //   selected: selectedPageName === 'MasterCalendarPage',
    //   disabled: false,
    //   linkProps: {
    //     name: 'MasterCalendarPage',
    //   },
    //   id: 'MasterCalendarPage',
    // },

    // {
    //   text: <FormattedMessage id="ManageListingsPage.QAPage" />,
    //   selected: selectedPageName === 'QAPage',
    //   disabled: false,
    //   linkProps: {
    //     name: 'QAPage',
    //   },
    //   id: 'QAPage',
    // },
  ];

  if (options) {
    tabs = tabs.filter(t => options.includes(t.id));
  }

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
};

export default UserNav;
