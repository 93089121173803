import React, { useState } from 'react';
import { Field } from 'react-final-form';
import css from './FieldToogle.module.css';
import './switch.css';
import Switch from '@mui/material/Switch';

const FieldToogle = props => {
  const { id, name, label } = props;

  const toogleField = ({ input: { onChange, value }, ...rest }) => {
    const [checked, setChecked] = useState(value[0] && value[0] === 'true');
    const handleChange = event => {
      setChecked(event.target.checked);
      onChange([`${event.target.checked}`]);
    };

    return (
      <div className={css.toogleWrapper}>
        <div className={css.switchLabel}>{label}</div>
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </div>
    );
  };

  return <Field id={id} name={name} component={toogleField} />;
};

export default FieldToogle;
