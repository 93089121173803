import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingPricingPanel.module.css';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData } = currentListing.attributes;
  const { specialPackageNumberOfDays } = publicData;

  const specialPackagePrice =
    publicData && publicData.specialPackagePrice
      ? new Money(publicData.specialPackagePrice, config.currency)
      : undefined;
  const specialPackageExtraDayPrice =
    publicData && publicData.specialPackageExtraDayPrice
      ? new Money(publicData.specialPackageExtraDayPrice, config.currency)
      : undefined;
  const shippingCostInitialValue =
    publicData?.shippingCost && new Money(publicData?.shippingCost, config.currency);
  const rrpInitialValue = publicData?.rrp && new Money(publicData?.rrp, config.currency);
  const maxNumberOfDays = publicData && publicData.maxNumberOfDays;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
  );

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;

  let packagesOptions = [];
  for (let i = 1; i <= 30; i++) {
    if (publicData[`option_${i}_name`] && publicData[`option_${i}_values`]) {
      const packagesPairs = Object.entries(publicData[`option_${i}_values`]);

      packagesPairs.forEach(p => {
        if (p[0] && p[1]) {
          const packageObj = {
            label: p[0],
            amount: p[1],
            numberOfDays: p[0]?.includes('4') ? 4 : 8,
          };
          packagesOptions.push(packageObj);
        }
      });
    }
  }

  const packagePrice4DaysFromImport = packagesOptions.find(p => p?.numberOfDays === 4);
  const packagePrice4DaysInitialValueFromImport =
    packagePrice4DaysFromImport && new Money(packagePrice4DaysFromImport.amount, config.currency);

  const packagePrice8DaysFromImport = packagesOptions.find(p => p?.numberOfDays === 8);
  const packagePrice8DaysInitialValueFromImport =
    packagePrice8DaysFromImport && new Money(packagePrice8DaysFromImport.amount, config.currency);

  const packagePrice4DaysInitialValue =
    (publicData.packagePrice4Days && new Money(publicData.packagePrice4Days, config.currency)) ||
    packagePrice4DaysInitialValueFromImport;
  const packagePrice8DaysInitialValue =
    (publicData.packagePrice8Days && new Money(publicData.packagePrice8Days, config.currency)) ||
    packagePrice8DaysInitialValueFromImport;
  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={{
        price,
        // specialPackageNumberOfDays,
        // specialPackagePrice,
        // specialPackageExtraDayPrice,
        packagePrice4Days: packagePrice4DaysInitialValue,
        packagePrice8Days: packagePrice8DaysInitialValue,
        shippingCost: shippingCostInitialValue,
        rrp: rrpInitialValue,
        maxNumberOfDays,
        isProductForSale: publicData?.isProductForSale,
        offers: publicData.offers,
      }}
      onSubmit={onSubmit}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      currentListing={currentListing}
      panelTitle={<h1 className={css.title}>{panelTitle}</h1>}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return <div className={classes}>{form}</div>;
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
