import * as React from 'react';
import {useState, useEffect} from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Field } from 'react-final-form';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import css from './SelectSearchableFilter.module.css';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


export default function CheckboxesTags(props) {
    const { id, options, name, className } = props;

    const initialValues = props.initialValues[name] && props.initialValues[name]?.length > 0 ? props.initialValues[name].map(v => {
        return {
            key: v,
            label: v
        }
    }) : [];


    const autocompleteField = ({ input: { onChange, value }, label, ...rest }) => {
        const [autocompleteValue, setAutocompleteValue] = useState([]);
        

        useEffect(() => {
            setAutocompleteValue(initialValues)
        },[initialValues])

      return (
        <div className={css.autocompleWrapper}>
                    <Autocomplete
                    multiple
                    value={autocompleteValue}
                    onChange={(event, newValue) => {
                      setAutocompleteValue(newValue);
                      onChange(newValue.map(v => { return v.key }));
                    }}
                    // inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    className={className}
                    id={id}
                    name={name}
                    options={options.filter(o => {
                        return !initialValues.find(i => {return i.key === o.key})
                    })}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.label}
                        </li>
                    )}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                        <TextField {...params}  placeholder="Search designers" />
                    )}
                    />
        </div>
      )}

  return (
    <Field
    name={name}
    // label={label}
    component={autocompleteField}
  />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
