import React, { Component } from 'react';
import { string, bool, arrayOf, array, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';
import config from '../../config';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { required, bookingDatesRequired, composeValidators } from '../../util/validators';
import { START_DATE, END_DATE } from '../../util/dates';
import { propTypes } from '../../util/types';
import {
  Form,
  IconSpinner,
  PrimaryButton,
  FieldDateRangeInput,
  Button,
  FieldTextInput,
  FieldSelect,
  FieldCurrencyInput,
} from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import css from './BookingDatesForm.module.css';
import { times } from 'lodash';

const identity = v => v;

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { focusedInput: null, referralActivated: false, agreed: false };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    const { startDate, endDate } = e.bookingDates || {};
    const { quantity, donation } = e;

    if ((quantity || donation) && this.props.timeSlots.length > 0) {
      e.bookingDates = {
        startDate: this.props.timeSlots[0].attributes.start,
        endDate: this.props.timeSlots[0].attributes.end,
      };
      this.props.onSubmit(e);
    }

    if (!startDate) {
      if (e.preventDefault) {
        e.preventDefault();
      }
      this.setState({ focusedInput: START_DATE });
    } else if (!endDate) {
      if (e.preventDefault) {
        e.preventDefault();
      }
      this.setState({ focusedInput: END_DATE });
    } else {
      this.props.onSubmit(e);
    }
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { quantity, donation } = formValues.values;
    const donationAmount = donation && donation.amount;
    const { startDate, endDate } =
      formValues.values && formValues.values.bookingDates ? formValues.values.bookingDates : {};
    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    if (donationAmount && this.props.isDonationListing) {
      this.props.onFetchTransactionLineItems({
        bookingData: { quantity, proposedPrice: this.props.proposedPrice, donationAmount },
        listingId,
        isOwnListing,
      });
    }

    if (quantity && !this.props.fetchLineItemsInProgress && !this.props.isDonationListing) {
      const isAll20Obj = this.props.isAll20 ? { isAll20: true } : {};
      this.props.onFetchTransactionLineItems({
        bookingData: { quantity, proposedPrice: this.props.proposedPrice, ...isAll20Obj },
        listingId,
        isOwnListing,
      });
    }

    if (
      startDate &&
      endDate &&
      !this.props.fetchLineItemsInProgress &&
      !this.props.isDonationListing
    ) {
      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate },
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      referral,
      handleReferralClick,
      referralLeftForSenderUser,
      openOfferModal,
      fromTransactionPage,
      proposedPrice,
      onContactUser,
      openSwapModal,
      acceptingOffers,
      isDonationListing,
      isCarbonFootprint,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const referralAvailable = referral ? !referral.used : false;
    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    const stock = this.props.listing?.attributes.publicData.stock;
    const stockNumbersArray = stock ? Array.from({ length: parseInt(stock) }, (_, i) => i + 1) : [];

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        initialValues={this.props.isProductForSale ? { quantity: 1 } : {}}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            formId,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            unitType,
            values,
            timeSlots,
            fetchTimeSlotsError,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            isProductForSale,
            listing,
            form,
          } = fieldRenderProps;
          const { quantity } = values;
          const { startDate, endDate } = values && values.bookingDates ? values.bookingDates : {};
          const bookingStartLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingEndTitle',
          });
          const requiredMessage = intl.formatMessage({
            id: 'BookingDatesForm.requiredDate',
          });
          const startDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidStartDate',
          });
          const endDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidEndDate',
          });
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          // This is the place to collect breakdown estimation data.
          // Note: lineItems are calculated and fetched from FTW backend
          // so we need to pass only booking data that is needed otherwise
          // If you have added new fields to the form that will affect to pricing,
          // you need to add the values to handleOnChange function
          const bookingData = quantity
            ? { quantity }
            : startDate && endDate
            ? {
                unitType,
                startDate,
                endDate,
              }
            : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const dateFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };

          const now = moment();
          const today = now.startOf('day').toDate();
          const tomorrow = now
            .startOf('day')
            .add(1, 'days')
            .toDate();
          const startDatePlaceholderText =
            startDatePlaceholder || intl.formatDate(today, dateFormatOptions);
          const endDatePlaceholderText =
            endDatePlaceholder || intl.formatDate(tomorrow, dateFormatOptions);
          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const setAgreed = value => {
            this.setState({ agreed: value });
          };
          const maxNumberOfDays =
            listing?.attributes?.publicData?.maxNumberOfDays &&
            Number(listing?.attributes?.publicData?.maxNumberOfDays);

          let filteredTimeSlots = timeSlots;
          const startDateSelected = values && values.bookingDates?.startDate;
          if (startDateSelected && maxNumberOfDays && filteredTimeSlots) {
            filteredTimeSlots = filteredTimeSlots.filter(t => {
              const maximumEndDate = startDateSelected.addDays(maxNumberOfDays + 1);
              return t.attributes.end < maximumEndDate;
            });
          }

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              {timeSlotsError}
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />

              {isDonationListing ? (
                <>
                  <FieldCurrencyInput
                    className={css.donationAmountField}
                    id="donation"
                    name="donation"
                    autoFocus
                    label={isCarbonFootprint ? 'Amount' : 'Donation amount'}
                    placeholder={'Type amount in here...'}
                    currencyConfig={config.currencyConfig}
                  />

                  {bookingInfoMaybe}
                  {/* {bookingInfoMaybe && referralAvailable ? (
                <>
                  {referralLeftForSenderUser ? (
                    <center>
                      <p>{`You have ${referralLeftForSenderUser} referral ${
                        referralLeftForSenderUser > 1 ? 'discounts' : 'discount'
                      } left`}</p>
                    </center>
                  ) : null}
                  <Button id="referral" name="referral" onClick={handleReferralClick}>
                    Use $5 referral discount
                  </Button>
                </>
              ) : null} */}

                  {loadingSpinnerMaybe}
                  {bookingInfoErrorMaybe}

                  <PrimaryButton type="submit" disabled={!this.state.agreed}>
                    {isCarbonFootprint ? 'Proceed to payment' : 'Donate'}
                  </PrimaryButton>
                </>
              ) : (
                <>
                  {isProductForSale ? null : (
                    <FieldDateRangeInput
                      className={css.bookingDates}
                      name="bookingDates"
                      unitType={unitType}
                      startDateId={`${formId}.bookingStartDate`}
                      startDateLabel={bookingStartLabel}
                      startDatePlaceholderText={startDatePlaceholderText}
                      endDateId={`${formId}.bookingEndDate`}
                      endDateLabel={bookingEndLabel}
                      endDatePlaceholderText={endDatePlaceholderText}
                      focusedInput={this.state.focusedInput}
                      onFocusedInputChange={this.onFocusedInputChange}
                      format={identity}
                      timeSlots={filteredTimeSlots}
                      useMobileMargins
                      validate={composeValidators(
                        required(requiredMessage),
                        bookingDatesRequired(startDateErrorMessage, endDateErrorMessage)
                      )}
                      disabled={fetchLineItemsInProgress}
                      maxNumberOfDays={maxNumberOfDays}
                    />
                  )}

                  {isProductForSale ? (
                    <FieldSelect
                      className={css.quantityField}
                      id="quantity"
                      name="quantity"
                      label="Choose a quantity"
                      validate={required('You need to pick a quantity')}
                    >
                      <option value="">Pick something...</option>
                      {stockNumbersArray.map(nr => {
                        return (
                          <option value={nr}>{`${nr} ${nr === 1 ? 'piece' : 'pieces'}`}</option>
                        );
                      })}
                    </FieldSelect>
                  ) : null}

                  {bookingInfoMaybe}
                  {/* {bookingInfoMaybe && referralAvailable ? (
                <>
                  {referralLeftForSenderUser ? (
                    <center>
                      <p>{`You have ${referralLeftForSenderUser} referral ${
                        referralLeftForSenderUser > 1 ? 'discounts' : 'discount'
                      } left`}</p>
                    </center>
                  ) : null}
                  <Button id="referral" name="referral" onClick={handleReferralClick}>
                    Use $5 referral discount
                  </Button>
                </>
              ) : null} */}

                  {loadingSpinnerMaybe}
                  {bookingInfoErrorMaybe}

                  <p className={css.smallPrint}>
                    <FormattedMessage
                      id={
                        isOwnListing
                          ? 'BookingDatesForm.ownListing'
                          : 'BookingDatesForm.youWontBeChargedInfo'
                      }
                    />
                  </p>
                </>
              )}

              <div className={submitButtonClasses}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.agreed}
                        onChange={e => {
                          setAgreed(e.target.checked);
                        }}
                        className={css.checkbox}
                      />
                    }
                    label={
                      <p className={css.tAndCLabel}>
                        I agree with the <a>Terms & conditions</a>
                      </p>
                    }
                  />
                </FormGroup>

                {!isDonationListing && (
                  <PrimaryButton type="submit" disabled={!this.state.agreed}>
                    {isProductForSale ? (
                      <FormattedMessage id="BookingDatesForm.addToCart" />
                    ) : (
                      <FormattedMessage id="BookingDatesForm.requestToBook" />
                    )}
                  </PrimaryButton>
                )}

                {!isDonationListing && (
                  <PrimaryButton
                    type="button"
                    onClick={openSwapModal}
                    className={css.makeAnOfferButton}
                    // disabled={!this.state.agreed}
                  >
                    Request swap
                  </PrimaryButton>
                )}

                {/* TODO uncomment to enable price negociation */}
                {/* {isProductForSale && !fromTransactionPage && acceptingOffers ? (
                  <PrimaryButton
                    type="button"
                    onClick={openOfferModal}
                    className={css.makeAnOfferButton}
                    disabled={!this.state.agreed}
                  >
                    <FormattedMessage id="BookingDatesForm.makeOffer" />
                  </PrimaryButton>
                ) : null} */}

                {/* <PrimaryButton style={{ marginTop: '40px' }} type="button" onClick={onContactUser}>
                  <FormattedMessage id="BookingDatesForm.askQuestion" />
                </PrimaryButton> */}
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
