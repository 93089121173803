import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

export const originPrefix = '-origin';

export const imagesConfig = [
  {
    label: 'Headshot photo',
    key: 'headshotPhoto',
    placeholder: '+ Add Headshot Photo',
    countLimit: 1,
    photoIdKey: 'headshotPhotoIds',
  },
  {
    label: 'Featured Work Photo',
    key: 'featuredWorkPhoto',
    placeholder: '+ Add Featured Work Photo',
    countLimit: 1,
    photoIdKey: 'featuredWorkPhotoIds',
  },
  // {
  //   label: 'Cover Photo',
  //   key: 'coverPhoto',
  //   placeholder: '+ Add Cover Photo',
  //   countLimit: 1,
  //   photoIdKey: 'coverPhotoIds',
  // },
  {
    label: 'Portfolio Photo',
    key: 'portofolioPhoto',
    placeholder: '+ Add Portfolio Photo',
    countLimit: 20,
    photoIdKey: 'portofolioPhotoIds',
  },
];

export const convertImagesToPublicDataInfo = (images, publicData) => {
  let publicDataInfo = {};

  imagesConfig.forEach(conf => {
    const photos = images.filter(img => img?.file?.name === conf.key);
    const newPhotoIds = photos.map(img => img?.imageId?.uuid);
    const alreadySavedPhotosIds = publicData[conf.photoIdKey] || [];
    const allPhotosArray = [...newPhotoIds, ...alreadySavedPhotosIds];
    //we remove the ids which do not exists anymore
    const filteredPhotosArray = allPhotosArray.filter(i =>
      images.find(x => x?.id?.uuid === i || x?.imageId?.uuid === i)
    );
    if (newPhotoIds?.length > 0) {
      publicDataInfo[conf.photoIdKey] = filteredPhotosArray;
    }
  });

  //we also need to save the original images
  imagesConfig.forEach(conf => {
    const photos = images.filter(img => img?.file?.name === conf.key + originPrefix);
    const newPhotoIds = photos.map(img => img?.imageId?.uuid);
    const alreadySavedPhotosIds = publicData[conf.photoIdKey + originPrefix] || [];
    const allPhotosArray = [...newPhotoIds, ...alreadySavedPhotosIds];
    //we remove the ids which do not exists anymore
    const filteredPhotosArray = allPhotosArray.filter(i =>
      images.find(x => x?.id?.uuid === i || x?.imageId?.uuid === i)
    );
    if (newPhotoIds?.length > 0) {
      publicDataInfo[conf.photoIdKey + originPrefix] = filteredPhotosArray;
    }
  });

  return publicDataInfo;
};

export const filterImagesValues = (key, images, publicData) => {
  // images.filter(img => img?.file?.name === key);
  const photoIdKey = imagesConfig.find(img => img?.key === key)?.photoIdKey;
  const photoIds = publicData[photoIdKey];

  const freshImages = images.filter(img => img?.file?.name === key);
  const alreadySavedImages = images.filter(
    img => photoIds?.length > 0 && img?.id?.uuid && photoIds.includes(img?.id?.uuid)
  );

  const finalImagesValues = [...alreadySavedImages, ...freshImages];
  return finalImagesValues;
};

export const removeOriginImageFile = (onRemoveImage, imageId, publicData, allImages) => {
  const croppedImageUuid = imageId.uuid;
  const croppedImageCategory = imagesConfig.find(c => {
    const categoryKey = c.photoIdKey;
    const foundCategory =
      publicData[categoryKey] && publicData[categoryKey].includes(croppedImageUuid);
    return foundCategory;
  });

  const croppedImageCategoryLabel = croppedImageCategory?.photoIdKey;
  const indexOfCroppedImage =
    croppedImageCategoryLabel && publicData[croppedImageCategoryLabel]?.indexOf(croppedImageUuid);

  if (croppedImageCategoryLabel) {
    const originImageCategoryLabel = croppedImageCategoryLabel + originPrefix;
    const originImageUuid =
      publicData[originImageCategoryLabel] &&
      publicData[originImageCategoryLabel][indexOfCroppedImage];
    const newUuid = new UUID(originImageUuid);
    onRemoveImage(newUuid); //TODO origin images is still present under the images key of the listing
  }
};
