import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, FieldTextInput, FieldSelect } from '../../components';
import FieldToogle from '../../components/FieldToogle/FieldToogle';
import css from './EditListingPricingForm.module.css';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        currentListing,
        panelTitle,
        values,
      } = formRenderProps;

      const isProductForSale = values.isProductForSale && values.isProductForSale[0] === 'true';
      console.log(isProductForSale);
      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = isProductForSale
        ? 'EditListingPricingForm.pricePerItem'
        : isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );

      const stockRequired = validators.numberAtLeast(
        intl.formatMessage({ id: 'EditListingPricingForm.stockRequired' }),
        0
      );

      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      const toogleLabel =
        values.isProductForSale && values.isProductForSale[0] === 'true' ? 'For sale' : 'For rent';
      const isForSale = toogleLabel === 'For sale';

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          <div className={css.headingWrapper}>
            {panelTitle}
            <FieldToogle id="isProductForSale" name={'isProductForSale'} label={toogleLabel} />
          </div>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}

          {/* TODO enable for price negociation */}
          {/* {isForSale && (
            <FieldSelect
              id="offers"
              name="offers"
              label="Accepting offers?"
              className={css.selectField}
              validate={validators.required('You need to select an option')}
            >
              <option value="" disabled hidden>
                Pick an option...
              </option>
              <option value={'no'}>{'No'}</option>
              <option value={'yes'}>{'Yes'}</option>
            </FieldSelect>
          )} */}

          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.priceInput}
            autoFocus
            label={pricePerUnitMessage}
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />

          <FieldCurrencyInput
            id="rrp"
            name="rrp"
            className={css.priceInput}
            autoFocus
            label={'Recommended retail price • optional'}
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            // validate={priceValidators}
          />

          <FieldCurrencyInput
            id="shippingCost"
            name="shippingCost"
            className={css.priceInput}
            autoFocus
            label={
              isProductForSale ? 'Shipping cost • optional' : 'Shipping including return parcel'
            }
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />

          <p> </p>
          {/* {isProductForSale ? 
            <FieldTextInput
            className={css.field}
            type="number"
            id="stock"
            name="stock"
            placeholder="5"
            label="Available stock"
            validate={stockRequired}
          /> : null} */}

          {/* {!isProductForSale && (
            <FieldTextInput
              className={css.field}
              type="number"
              id="maxNumberOfDays"
              name="maxNumberOfDays"
              placeholder="3"
              label="Max amount of days (we recommend 15 days)"
              validate={validators.numberAtLeast(
                intl.formatMessage({ id: 'EditListingPricingForm.stockRequired' }),
                1
              )}
            />
          )} */}

          {!isProductForSale ? (
            <>
              <p className={css.label}>Special packages</p>

              <FieldCurrencyInput
                id="packagePrice4Days"
                name="packagePrice4Days"
                className={css.priceInput}
                label={'Price for 4 days package'}
                placeholder={pricePlaceholderMessage}
                currencyConfig={config.currencyConfig}
                // validate={priceValidators}
              />

              <FieldCurrencyInput
                id="packagePrice8Days"
                name="packagePrice8Days"
                className={css.priceInput}
                label={'Price for 8 days package'}
                placeholder={pricePlaceholderMessage}
                currencyConfig={config.currencyConfig}
                // validate={priceValidators}
              />
            </>
          ) : null}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {isProductForSale ? (
              <FormattedMessage id="EditListingWizard.saveNewPricingPerItem" />
            ) : (
              saveActionMsg
            )}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
